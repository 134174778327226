import { initLoginAppFluent as initLoginAppStateFluent } from "@msidentity/SISU/apps/login/model/init-login-app-fluent";
import { type LoginAppFluentProps } from "@msidentity/SISU/apps/login/model/login-app-types-fluent";
import { type Flavors, ViewId } from "@msidentity/SISU/constants";
import { shouldAddUserNameToInitialEntries } from "@msidentity/SISU/flows/login/login-util";
import { type LoginFlowFluentProps } from "@msidentity/SISU/flows/login/model/login-flow-types-fluent";
import { getRouteFromViewId } from "@msidentity/SISU/utilities/routing-helper";
import { type ServerData } from "@msidentity/SISU/utilities/server-data";
import {
  InitialViewPickerViewFluent,
  PasswordEntryViewFluent,
  PhoneDisambiguationViewFluent,
  UsernameEntryViewFluent,
} from "../../../flows/login";

export const loginFlowViewProps: LoginFlowFluentProps = {
  InitialViewPickerViewFluent,
  PasswordEntryViewFluent,
  PhoneDisambiguationViewFluent,
  UsernameEntryViewFluent,
};

/**
 * Creates the initial state (config/context) from the ServerData object sent from MSA, and defines the view containers needed for the login app.
 * IMPORTANT: This should be called at module scope, NOT inside the render function!!
 * @param serverData MSA ServerData object sent from the server and read from the window object.
 * @param activeFlavor Rendered flavor
 * @returns The initial state and config for the login app, as well as the corresponding view containers.
 */
export function initLoginAppFluent(
  serverData: ServerData,
  activeFlavor: Flavors,
): LoginAppFluentProps {
  const {
    initialProviderState,
    initialAuthenticationState,
    initialLoginState,
    initialChallengeState,
  } = initLoginAppStateFluent(serverData, activeFlavor);

  // This will add username view to the stack giving a back arrow and navigation to go 'back' to username
  // in case of an error on another view
  const initialEntries = shouldAddUserNameToInitialEntries()
    ? [getRouteFromViewId(ViewId.Username), "/"]
    : ["/"];

  return {
    initialGlobalState: initialProviderState.initialGlobalState,
    initialFluentThemeState: initialProviderState.initialFluentThemeState,
    initialHistoryState: initialProviderState.initialHistoryState,
    authenticationState: initialAuthenticationState,
    loginState: initialLoginState,
    challengeState: initialChallengeState,
    loginFlowViewProps,
    initialEntries,
  };
}
