import { ConfigWrapper } from "../../utilities/config-wrapper";
import { type ServerData } from "../../utilities/server-data";
import { getValidatedCobrandingObject } from "../get-validated-cobranding-object";
import { type FluentCobrandingProps } from "./fluent-cobranding-interface";
import { FluentCobrandingPropsMap } from "./fluent-cobranding-props-map";

export const defaultFluentCobrandingConfig = {
  cobrandingProps: {} as FluentCobrandingProps,
};

export type FluentCobrandingConfigType = typeof defaultFluentCobrandingConfig;

// eslint-disable-next-line deprecation/deprecation
const configWrapper = new ConfigWrapper(defaultFluentCobrandingConfig);

export default configWrapper;

/* ********* ServerData helpers ********** */

/**
 * Initialize the cobranding config object from ServerData
 * @param serverData The IDP-specific server data object that should be used to initialize the cobranding config

 */
export function initFluentCobrandingConfig(serverData: ServerData) {
  const mappedServerData: Partial<FluentCobrandingConfigType> = {};

  if (serverData.sCobrandId && serverData.sClientId) {
    mappedServerData.cobrandingProps = getValidatedCobrandingObject(
      serverData,
      FluentCobrandingPropsMap,
    ) as FluentCobrandingProps;
  }

  configWrapper.initialize(mappedServerData);
}
