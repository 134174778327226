import React from "react";
import { FormattedTextWithBindingsFluent } from "@msidentity/SISU/utilities/formatted-text-with-bindings";
import { useUsernameErrorBindingsFluent } from "./use-username-error-bindings-fluent";

/**
 * This hook is used to get the function for getErroMessage to be passed to useGct.
 * @returns a function that returns a FormattedTextWithBindingsFluent component with
 * the error message and embedded links for the username error message
 */
export const useGetErrorMessageForGctFluent = () => {
  const getEmbeddedLinkBindings = useUsernameErrorBindingsFluent();
  const getErrorMessage = (unsafeUsername: string, gctError: string) => {
    const { ...bindings } = getEmbeddedLinkBindings(unsafeUsername);
    return <FormattedTextWithBindingsFluent text={gctError} embeddedBindings={{ ...bindings }} />;
  };

  return getErrorMessage;
};
