import { type PasswordEntryViewRendererStringsFluent } from "@msidentity/sisu/views/password-entry/fluent/model/password-entry-view-types-fluent";

export type PasswordEntryViewStringsFluent = PasswordEntryViewRendererStringsFluent & {
  placeholder: string;
  errorStringIncorrectPassword: string;
  errorStringEmptyPassword: string;
};

export const passwordEntryStringsFluent: PasswordEntryViewStringsFluent = {
  title: getLocalString("SignIn_Password_Title"),
  forgotPasswordLinkLabel: getLocalString("ForgotYourPasswordLink"),
  placeholder: getLocalString("Password_Hint"),
  primaryButtonLabel: getLocalString("General_Buttons_Next"),
  errorStringIncorrectPassword: getLocalString("Login_IncorrectPassword_Error"),
  errorStringEmptyPassword: getLocalString("Login_EmptyPassword_Error"),
};
