import { useState } from "react";
import { ViewId } from "@msidentity/SISU/constants";
import {
  useFidoPostRedirect,
  useLoginFlowRedirect,
} from "@msidentity/SISU/flows/login/hooks/login-hooks";
import { loadAsyncIPv6Image } from "@msidentity/SISU/flows/login/initial-view-picker/model/load-async-ipv6-image";
import { redirectOnLoadIfNeeded } from "@msidentity/SISU/flows/login/initial-view-picker/model/redirect-on-load-if-needed";
import { useValidateExternalCanary } from "@msidentity/SISU/flows/login/initial-view-picker/model/use-validate-external-canary";
import LoginConfig from "@msidentity/SISU/flows/login/login-config";
import { LoginState } from "@msidentity/SISU/flows/login/login-constants";
import { useLoginContext } from "@msidentity/SISU/flows/login/login-context";
import { LoginActionType } from "@msidentity/SISU/flows/login/login-reducer";
import { useGlobalContext } from "@msidentity/SISU/global-context";
import { useEffectOnce } from "@msidentity/SISU/hooks";
import { determineInitialViewFluent } from "./determine-initial-view-fluent";

export const useInitialViewPickerFluent = () => {
  const {
    viewState: { credentials },
    dispatchStateChange: dispatchLoginStateChange,
  } = useLoginContext();

  const { loginMode, externalCanary, signupUrl, signupUrlPostParams } = LoginConfig.instance;
  const fidoRedirectCallback = useFidoPostRedirect();
  const loginFlowRedirect = useLoginFlowRedirect();

  const [initialViewId, setInitialViewId] = useState(ViewId.None);

  // External canary validation happens asynchronously, so we need to use a state variable
  // to store the result and track when we're ready to render.
  const invokeExternalCanaryValidation = useValidateExternalCanary();
  const hasExternalCanary = !!externalCanary;
  const [readyToRender, setReadyToRender] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [showRemoteConnectCanaryValidationView, setShowRemoteConnectCanaryValidationView] =
    useState(false);
  const {
    globalState: {
      debugInfo: { errorCode },
    },
  } = useGlobalContext();

  // useEffectOnce is used to only run this async process once, so we don't do it every time the
  // invokeExternalCanaryValidation callback is regenerated in useValidateExternalCanary in each render
  useEffectOnce(() => {
    loadAsyncIPv6Image();

    redirectOnLoadIfNeeded();

    const { nextViewId, actionFromPostedState } = determineInitialViewFluent(
      loginMode,
      credentials,
      errorCode,
    );

    if (nextViewId === ViewId.Fido) {
      setShouldRedirect(true);
      fidoRedirectCallback();
    }

    if (actionFromPostedState.doLoginFlowRedirect) {
      setShouldRedirect(true);
      const redirectParams = actionFromPostedState.loginFlowRedirectParams;
      loginFlowRedirect(
        redirectParams.redirectUrl || "",
        redirectParams.redirectPostParams,
        redirectParams.isIdpRedirect,
      );
    }

    if (hasExternalCanary) {
      invokeExternalCanaryValidation().then((nextView) => {
        if (nextView === LoginState.PostRedirect) {
          setShouldRedirect(true);
          loginFlowRedirect(signupUrl, signupUrlPostParams, false, false);
        }

        setShowRemoteConnectCanaryValidationView(
          nextView === LoginState.RemoteConnectCanaryValidation,
        );
        setReadyToRender(true);
      });
    } else {
      setReadyToRender(true);
    }

    // Update state once it is decided that no redirects are necessary
    setInitialViewId(nextViewId);
    dispatchLoginStateChange({
      type: LoginActionType.SetInitialViewId,
      payload: nextViewId,
    });
  });

  return {
    initialViewId,
    readyToRender,
    shouldRedirect,
    showRemoteConnectCanaryValidationView,
  };
};
