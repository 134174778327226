import React from "react";
import { Title3 } from "@fluentui/react-components";
import StylesConfigFluent from "../../../config/styles-config-fluent";
import { type TitleProps } from "../title-container-interface-fluent";

/**
 * Title component
 * @param props TitleProps
 * @returns a text component styled as a title.
 */
export const TitleFluent: React.FC<TitleProps> = function TitleFluent(props) {
  const { text } = props;

  const { titleStyles } = StylesConfigFluent.instance.useTitleStyles();

  return (
    <Title3 {...props} align="center" as="h1" className={titleStyles} data-testid="title">
      {text}
    </Title3>
  );
};
