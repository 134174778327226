import React, { useRef } from "react";
import { mergeClasses } from "@fluentui/react-components";
import StylesConfigFluent from "../../../config/styles-config-fluent";
import { useGlobalContext } from "../../../global-context";
import { useSetDefaultFocusFluent } from "../../../hooks/fluent/use-set-default-focus-fluent";
import { type FormattedTextWithBindingsFluentProps } from "../../../utilities/formatted-text-with-bindings/fluent/formatted-text-with-bindings-fluent";
import { type AccessibleImageProps, AccessibleImage } from "../../accessible-image";
import BannerContainerFluent from "../../banner-container/fluent/banner-container-fluent";
import {
  type ViewButtonContainerProps,
  ViewButtonContainerFluent,
} from "../../button/fluent/view-button-container-fluent";
import {
  type DescriptionFluentProps,
  DescriptionFluent,
} from "../../description/fluent/description-fluent";
import IdentityBannerFluent from "../../identity-banner/fluent/identity-banner-fluent";
import { ProgressIndicatorFullScreenFluent } from "../../loading-progress/fluent/progress-indicator-full-screen-fluent";
import { TitleContainerFluent } from "../../title/fluent/title-container-fluent";
import { type TitleContainerProps } from "../../title/title-container-interface-fluent";
import { type ViewFormProps, ViewFormContainer } from "../view-form-container";
import { CaptionFluent } from "./caption-fluent";
import { type ViewFooterFluentProps, ViewFooterFluent } from "./view-footer-fluent";

export type ViewContainerFluentProps = {
  buttonContainerProps?: ViewButtonContainerProps;
  captionTextProps?:
    | FormattedTextWithBindingsFluentProps
    | Array<FormattedTextWithBindingsFluentProps>;
  descriptionTextProps?: DescriptionFluentProps | DescriptionFluentProps[];
  formProps?: ViewFormProps;
  imageProps?: AccessibleImageProps;
  titleContainerProps?: TitleContainerProps;
  viewFooterProps?: ViewFooterFluentProps | ViewFooterFluentProps[];
  showFullScreenProgressIndicator?: boolean;
};

/**
 * View Container component
 * @param props ViewContainerProps
 * @param props.children All child components to render inside the view.
 * @param props.buttonContainerProps ViewButtonContainerProps
 * @param props.captionTextProps FormattedTextWithBindingsPropsFluent
 * @param props.descriptionTextProps DescriptionFluentProps
 * @param props.formProps ViewFormProps
 * @param props.imageProps AccessibleImageProps
 * @param props.titleContainerProps TitleContainerProps
 * @param props.viewFooterProps FormattedTextWithBindingsPropsFluent
 * @param props.showFullScreenProgressIndicator If we want to display full progress indicator on screen
 * @returns a container encapsulating the default view components.
 */
export const ViewContainerFluent: React.FC<ViewContainerFluentProps> =
  function ViewContainerFluent({
    children,
    buttonContainerProps,
    captionTextProps,
    descriptionTextProps,
    formProps,
    imageProps,
    titleContainerProps,
    viewFooterProps,
    showFullScreenProgressIndicator,
  }) {
    const {
      globalState: { user, showIdentityBanner, hideBannerLogo },
    } = useGlobalContext();

    const {
      captionWrapperStyles,
      secondaryCaptionWrapperStyles,
      viewContainerStyles,
      formStyles,
      imageWrapperStyles,
      secondaryDescriptionStyles,
      loadingScreenStyles,
    } = StylesConfigFluent.instance.useViewContainerStyles();

    const { sectionPaddingTopStyles } = StylesConfigFluent.instance.useCommonStyles();

    let captions;
    if (captionTextProps) {
      captions = captionTextProps instanceof Array ? captionTextProps : [captionTextProps];
    }

    let descriptions;
    if (descriptionTextProps) {
      descriptions =
        descriptionTextProps instanceof Array ? descriptionTextProps : [descriptionTextProps];
    }

    let viewFooters;
    if (viewFooterProps) {
      viewFooters = viewFooterProps instanceof Array ? viewFooterProps : [viewFooterProps];
    }

    // If an image is shown above the description, the first description will not need section padding top styles.
    const descriptionTopPadding = imageProps ? "" : sectionPaddingTopStyles;

    const ref = useRef(null);

    // This controls the default focus for all pages in Fluent and should be the primary location where we call useSetDefaultFocusFluent
    // Default focus will be set once on initial render of the Fluent view container, which is usually when the route changes
    // If the default focus ever causes problems for a specific use case, we can update to read/write excluded IDs from context
    useSetDefaultFocusFluent(ref, ["phoneCountry"]);

    const content = (
      <>
        {!hideBannerLogo && <BannerContainerFluent />}
        <div id="view" ref={ref} className={viewContainerStyles}>
          {showFullScreenProgressIndicator && (
            <div className={loadingScreenStyles}>
              <ProgressIndicatorFullScreenFluent label={getLocalString("General_Loading")} />
            </div>
          )}
          {!showFullScreenProgressIndicator && (
            <div>
              {showIdentityBanner && (
                <IdentityBannerFluent
                  userDisplayName={user.displayUsername.unsafeUnescapedString}
                />
              )}
              {/* Announce title to screen reader users so they know when the content has changed.
      This is required because we do not do actual route changes when we navigate. */}
              <div aria-live="polite">
                {titleContainerProps && <TitleContainerFluent {...titleContainerProps} />}
              </div>
              {imageProps && (
                <div className={mergeClasses(imageWrapperStyles, imageProps.className)}>
                  <AccessibleImage {...imageProps} />
                </div>
              )}
              {descriptions &&
                descriptions.map((descriptionProps, index) => (
                  <DescriptionFluent
                    {...descriptionProps}
                    key={descriptionProps.text}
                    wrapperStyleName={mergeClasses(
                      index === 0 ? descriptionTopPadding : secondaryDescriptionStyles,
                      descriptionProps.wrapperStyleName,
                    )}
                  />
                ))}
              {children}
              {captions &&
                captions.map((props: FormattedTextWithBindingsFluentProps, index) => (
                  <CaptionFluent
                    {...props}
                    key={props.text}
                    wrapperStyleName={
                      index === 0 ? captionWrapperStyles : secondaryCaptionWrapperStyles
                    }
                  />
                ))}
              {buttonContainerProps && (
                <ViewButtonContainerFluent
                  {...buttonContainerProps}
                  addMargin={captionTextProps === undefined}
                />
              )}
              {viewFooters &&
                viewFooters.map((props: ViewFooterFluentProps) => (
                  <ViewFooterFluent {...props} key={props.text} />
                ))}
            </div>
          )}
        </div>
      </>
    );

    if (formProps) {
      const className = mergeClasses(formProps.className, formStyles);

      const newFormProps = { ...formProps, className };
      return <ViewFormContainer form={newFormProps}>{content}</ViewFormContainer>;
    }

    return content;
  };
