import { createAuthenticationState } from "../../../authentication-context";
import { createChallengeState } from "../../../challenge-context";
import { footerConfigOverrides } from "../../../components/footer/footer-config-overrides";
import { type Flavors, FlowId } from "../../../constants";
import { initBaseSisuAppDataFluent } from "../../../context/sisu-providers/fluent/init-base-sisu-app-data-fluent";
import { initLoginConfig } from "../../../flows/login/login-config";
import { createLoginState } from "../../../flows/login/login-context";
import { type ServerData } from "../../../utilities/server-data";
import { getUserOptions } from "./get-user-options";

/**
 * Parse the ServerData object sent from Account and return the initial config/state for the app contexts.
 * IMPORTANT: This should be called at module scope, NOT inside the render function!!
 * @param serverData The ServerData object sent from the Account server and read from the window object.
 * @param activeFlavor The rendered flavor
 * @returns The initial state for the global and signup contexts.
 */
export const initLoginAppFluent = (serverData: ServerData, activeFlavor: Flavors) => {
  const initialProviderState = initBaseSisuAppDataFluent(
    serverData,
    activeFlavor,
    FlowId.Login,
    getUserOptions,
    footerConfigOverrides,
  );

  initLoginConfig(serverData);

  const initialLoginState = createLoginState(serverData);
  const initialAuthenticationState = createAuthenticationState(serverData);
  const initialChallengeState = createChallengeState(serverData);

  return {
    initialProviderState,
    initialAuthenticationState,
    initialLoginState,
    initialChallengeState,
  };
};
