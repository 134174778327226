import React from "react";
import { PasswordEntryViewRendererFluent } from "@msidentity/sisu/views/password-entry/fluent/password-entry-view-renderer-fluent";
import { loginInputValidationErrorStringsFluent } from "../../model/login-input-validation-error-strings-fluent";
import { passwordEntryStringsFluent } from "./model/password-entry-view-strings-fluent";
import { usePasswordEntryViewFluent } from "./model/use-password-entry-view-fluent";

/**
 * Fluent Password entry view component
 * @returns A rendered instance of this component
 */
export const PasswordEntryViewConnectorFluent: React.FC =
  function PasswordEntryViewConnectorFluent() {
    const props = usePasswordEntryViewFluent(
      passwordEntryStringsFluent,
      loginInputValidationErrorStringsFluent,
    );

    return <PasswordEntryViewRendererFluent {...props} />;
  };
