import React, { useRef } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Body1,
  mergeClasses,
  Subtitle2,
  Text,
  useId,
} from "@fluentui/react-components";
import { Dismiss24Filled, Dismiss24Regular } from "@fluentui/react-icons";
import StylesConfigFluent from "../../../config/styles-config-fluent";
import { defaultFocusTimeoutMs } from "../../../constants";
import GlobalConfig from "../../../global-config";
import { useGlobalContext } from "../../../global-context";
import { getTextAreaAndCopyToClipboard } from "../../../utilities/clipboard-helper";
import { createEmbeddedLinkFluent } from "../../../utilities/formatted-text-with-bindings";
import { replaceTokens } from "../../../utilities/strings-helper";
import { IconButtonFluent } from "../../button/fluent/icon-button-fluent";
import { DescriptionFluent } from "../../description/fluent/description-fluent";
import FooterConfig from "../../footer/footer-config";
import LinkFluent from "../../link/fluent/link-fluent";
import { DebugDetailsStringsConfigFluent } from "./debug-details-strings-config-fluent";

export type DebugDetailsFluentProps = {
  onCloseClick: () => void;
};

/**
 * @param props
 *  onCloseClick: Callback to execute when the close button is clicked
 * @returns the Debug details component containing relevant troubleshooting information related
 * to the request.
 */
const DebugDetailsFluent: React.FC<DebugDetailsFluentProps> = function DebugDetailsFluent(props) {
  const { onCloseClick } = props;
  const {
    globalState: {
      isWideView,
      debugInfo: { errorCode, errorMessage, timeStamp },
    },
  } = useGlobalContext();
  const { sessionId, correlationId, isHosted } = GlobalConfig.instance;
  const { helpUrl, helpUrlHosted } = FooterConfig.instance;
  const {
    debugDetailsStrings: {
      title,
      description,
      descriptionHosted,
      closeLabel,
      closeAriaLabel,
      copyInfo,
      troubleshootingDetails,
    },
  } = DebugDetailsStringsConfigFluent.instance;

  const { useLightboxLayoutStyles, useDebugDetailsStyles, useCommonStyles } =
    StylesConfigFluent.instance;
  const { defaultTextColorStyles } = useCommonStyles();
  const { inner, innerWideStyle } = useLightboxLayoutStyles();

  const innerStyleName = isWideView ? mergeClasses(inner, innerWideStyle) : inner;

  const {
    debugDetailsLightboxStyles,
    debugDetailsTitleContainerStyles,
    debugDetailsTitleStyles,
    debugDetailsSectionPaddingStyles,
  } = useDebugDetailsStyles();
  const debugDetailsStyles = mergeClasses(innerStyleName, debugDetailsLightboxStyles);
  const troubleshootingDetailsId = "troubleshootingDetails";
  const debugDetailsCloseButtonId = "debugDetailsCloseButton";
  const ref = useRef(null);

  // Set default focus on the title when debug details opens.
  // This opens outside of route changes so the default shell focus handling does not apply.
  // We focus on the title instead of the first focusable element to give screen reader users better context.
  const titleId = useId("debugDetailsTitle");
  setTimeout(() => {
    document.getElementById(titleId)?.focus();
  }, defaultFocusTimeoutMs);

  const getTroubleshootingDetailItem = (label: string, value: string) => (
    <div>
      <Body1 className={defaultTextColorStyles}>
        <Text weight="semibold">{label}: </Text>
        {value}
      </Body1>
    </div>
  );

  return (
    <div id="debugDetails" className={debugDetailsStyles} ref={ref}>
      <div className={debugDetailsTitleContainerStyles}>
        {/* Title is only focusable on initial load. It is not part of the tab order. */}
        <Subtitle2 className={debugDetailsTitleStyles} tabIndex={-1} id={titleId}>
          {title}
        </Subtitle2>
        <IconButtonFluent
          label={closeLabel}
          aria-label={closeAriaLabel}
          filledIcon={Dismiss24Filled}
          regularIcon={Dismiss24Regular}
          id={debugDetailsCloseButtonId}
          onClick={onCloseClick}
        />
      </div>
      <DescriptionFluent
        wrapperStyleName={debugDetailsSectionPaddingStyles}
        text={replaceTokens(isHosted ? descriptionHosted : description, helpUrlHosted)}
        embeddedBindings={{ supportLink: createEmbeddedLinkFluent({ url: helpUrl }) }}
      />
      <Accordion collapsible>
        <AccordionItem value={1}>
          <AccordionHeader>{troubleshootingDetails}</AccordionHeader>
          <AccordionPanel>
            {/* Troubleshooting details are intentionally not localized */}
            <div className={debugDetailsSectionPaddingStyles} id={troubleshootingDetailsId}>
              {errorCode && getTroubleshootingDetailItem("Error code", errorCode)}
              {sessionId && getTroubleshootingDetailItem("Request ID", sessionId)}
              {correlationId && getTroubleshootingDetailItem("Correlation ID", correlationId)}
              {timeStamp && getTroubleshootingDetailItem("Timestamp", timeStamp)}
              {errorMessage && getTroubleshootingDetailItem("Message", errorMessage)}
            </div>
            <div className={debugDetailsSectionPaddingStyles}>
              <LinkFluent onClick={() => getTextAreaAndCopyToClipboard(troubleshootingDetailsId)}>
                {copyInfo}
              </LinkFluent>
            </div>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default DebugDetailsFluent;
