import { Flavors } from "../../../constants";
import {
  type AgreementViewStringsFluent,
  AgreementViewStringsConfigFluent,
  agreementViewStringsFluent,
} from "./agreement-view-strings-config-fluent";

/**
 * Maps a flavor (i.e Xbox, Fluent) to a set of flavored strings.
 * @param flavor The active flavor.
 */
const agreementViewStringsFluentMap = new Map<Flavors, AgreementViewStringsFluent>([
  [Flavors.Fluent, agreementViewStringsFluent],
]);

/**
 * Initialize strings
 * @param flavor The active flavor.
 */
export function initAgreementViewStringsFluent(flavor: Flavors) {
  AgreementViewStringsConfigFluent.initialize({
    agreementViewStrings: agreementViewStringsFluentMap.get(flavor),
  });
}
