export const logoHeight = 24;

export const MicrosoftLogoColors = {
  msLogoBlue: "#00A4EF",
  msLogoGreen: "#7FBA00",
  msLogoRed: "#F25022",
  msLogoTextGrey: "#737373",
  msLogoWhite: "#FFFFFF",
  msLogoYellow: "#FFB900",
};
