import {
  type FieldValidationState,
  type InputStateOptionsFluent,
} from "../../input/fluent/input-types-fluent";
import { type InputState, useInput } from "../use-input";

export type PasswordInputStateOptionsFluent = InputStateOptionsFluent & {
  hintText?: string;
  hasInitialFocus?: boolean;
};

/**
 * This method takes the InputState and determines the validationState and validationMessage based on the state.
 * Unlike `determineValidation`, this method also supports a `success` state if there is no error.
 * @param inputState the InputState from `useInput`
 * @param hintText the default `validationMessage` to show when the input is not in an error state
 * @returns an object containing the validationState and validationMessage, used for Fluent components
 */
export const determinePasswordValidation = (inputState: InputState, hintText?: string) => {
  const {
    error: { showErrorMessage, hasError, errorMessage },
    value,
  } = inputState;
  const hasValue = !!value;
  // Default state to none with the hintText as the validationMessage
  let validationState: FieldValidationState = "none";
  let validationMessage: string | JSX.Element = hintText || "";

  // If we have an error to show, show the error and set the state to error
  if (showErrorMessage && hasError) {
    validationState = "error";
    // TODO: Default to placeholder instead of error message for local errors?
    validationMessage = errorMessage;
    // If we have a value and no error but a message to show, set the state to success
  } else if (hasValue && !hasError && hintText) {
    validationState = "success";
  }

  return {
    validationState,
    validationMessage,
  };
};

/**
 * This hooks uses the base `useInput` hook to create/manage state for a password input.
 * It also determines the validation state based on the input state to provide a Fluent component.
 * @param options The input state options, with a `hintText` added and `useElementRef` removed (as it is always true).
 * @returns the InputState for any form submission; the inputProps and fieldProps for use with InputFluent
 */
export const usePasswordInputFluent = (options: PasswordInputStateOptionsFluent) => {
  const { hintText } = options;
  // Hard-code the useInput to always use elementRef
  const inputState = useInput({
    ...options,
    useElementRef: true,
    immediatelyShowValidationErrorsAfterSubmit: false,
  });
  // Determine the validation state using the input state and hintText
  const fieldProps = determinePasswordValidation(inputState, hintText);

  // Before submission we don't want to bother showing errors until they click submit
  // After the first submit, we don't want to show errors until they blur the input (or click submit again)
  // This is to avoid the "noisiness" of the previous Fabric implementation which would show new validation errors immediately after submission
  const onBlur = () => {
    const {
      userHasSubmitted,
      error: { validationError, setShowErrorMessage },
    } = inputState;
    // If the user has submitted and has a validation error, we want to show the error message
    if (userHasSubmitted && validationError) {
      setShowErrorMessage(true);
    }
  };

  const inputProps = {
    value: String(inputState.value),
    onChange: inputState.onChange,
    ref: inputState.elementRef!, // We know that elementRef is defined because we are using useElementRef
    onBlur,
  };

  return {
    inputState,
    inputProps,
    fieldProps,
  };
};

export type PasswordStateFluent = ReturnType<typeof usePasswordInputFluent>;
