import { useEffect } from "react";
import {
  type Theme,
  createDarkTheme,
  createLightTheme,
  webDarkTheme,
  webLightTheme,
} from "@fluentui/react-components";
import FluentCobrandingConfig from "../../../cobranding/fluent/fluent-cobranding-config";
import { defaultGamingDarkThemeColorOverrides } from "../../../cobranding/fluent/fluent-cobranding-constants";
import {
  type FluentCobrandingProps,
  FluentCobrandingThemeLock,
} from "../../../cobranding/fluent/fluent-cobranding-interface";
import { useMatchMediaQuery } from "../../../hooks";
import { getHighContrastTheme } from "../../../utilities/browser-helper";
import { type FluentThemeState, useFluentThemeContext } from "./fluent-theme-context";
import { FluentThemeActionType } from "./fluent-theme-reducer";

export const createFluentTheme = (
  isDarkTheme: boolean,
  isGaming: boolean,
  cobrandingProps: FluentCobrandingProps,
): Theme => {
  if (isDarkTheme) {
    const darkThemeProps = cobrandingProps?.darkTheme;
    const darkTheme = darkThemeProps
      ? createDarkTheme(darkThemeProps.themeColorRamp)
      : webDarkTheme;

    const gamingOverrides = isGaming ? defaultGamingDarkThemeColorOverrides : {};

    return {
      ...darkTheme,
      ...gamingOverrides,
      ...darkThemeProps?.themeColorOverrides,
    };
  }

  const lightThemeProps = cobrandingProps?.lightTheme;
  const lightTheme = lightThemeProps
    ? createLightTheme(lightThemeProps.themeColorRamp)
    : webLightTheme;

  return {
    ...lightTheme,
    ...lightThemeProps?.themeColorOverrides,
  };
};

/**
 * Determines the user's current theme preference and high contrast settings
 * Watches for changes and updates the theme context if either setting is changed.
 */
export const useDetectFluentTheme = () => {
  const { dispatchStateChange } = useFluentThemeContext();
  const prefersDark = useMatchMediaQuery("(prefers-color-scheme: dark)");
  // this will trigger a rerender if high contrast settings change
  useMatchMediaQuery("(forced-colors: active)");
  const highContrastTheme = getHighContrastTheme();

  const { cobrandingProps } = FluentCobrandingConfig.instance;

  const isGaming = cobrandingProps?.isGaming ?? false;
  const isDarkModeOnly = cobrandingProps?.themeLock === FluentCobrandingThemeLock.DarkModeOnly;
  const isLightModeOnly = cobrandingProps?.themeLock === FluentCobrandingThemeLock.LightModeOnly;

  const isDarkTheme = (prefersDark && !isLightModeOnly) || isDarkModeOnly;

  useEffect(() => {
    const updatedState: FluentThemeState = {
      theme: createFluentTheme(isDarkTheme, isGaming, cobrandingProps),
      isDarkTheme,
      highContrastTheme,
      isGaming,
    };

    dispatchStateChange({ type: FluentThemeActionType.SetThemeAction, payload: updatedState });
  }, [cobrandingProps, dispatchStateChange, isGaming, isDarkTheme, highContrastTheme]);
};
