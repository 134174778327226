import { makeStyles, shorthands } from "@fluentui/react-components";

const useCriticalErrorPageStylesFluent = makeStyles({
  errorComponentStyles: {
    width: "90%",
    ...shorthands.margin(0, "auto"),
  },

  errorTitleStyles: {
    fontSize: "34px",
    fontWeight: "200",
    lineHeight: "40px",
    ...shorthands.padding("28px, 0px"),
  },
});

export default useCriticalErrorPageStylesFluent;

export type CriticalErrorPageStylesDefaultFluent = ReturnType<
  typeof useCriticalErrorPageStylesFluent
>;
