import { ConfigWrapper } from "../../../utilities/config-wrapper";

export const defaultDebugDetailsStringsFluent = {
  title: getLocalString("DebugDetails_Help_Title"),
  description: getLocalString("DebugDetails_LinkToSupport"),
  descriptionHosted: getLocalString("DebugDetails_LinkToSupport_Hosted"),
  troubleshootingDetails: getLocalString("DebugDetails_Title"),
  copyInfo: getLocalString("General_CopyInfo"),
  closeLabel: getLocalString("General_Close"),
  closeAriaLabel: getLocalString("DebugDetails_Close_AriaLabel"),
};

export type DebugDetailsStringsFluent = typeof defaultDebugDetailsStringsFluent;

// eslint-disable-next-line deprecation/deprecation
export const DebugDetailsStringsConfigFluent = new ConfigWrapper({
  debugDetailsStrings: defaultDebugDetailsStringsFluent,
});
