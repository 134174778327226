import { useResetPasswordUrl } from "@msidentity/SISU/flows/login/hooks/login-hooks";
import { useRedirect } from "@msidentity/SISU/hooks";

/**
 * Hook to handle the redirect when forgot password link is selected
 * @returns Handler that should be triggered when user selects the forgot password link
 */
export const useForgotPasswordLinkHandler = () => {
  const resetPasswordUrl = useResetPasswordUrl();
  const redirect = useRedirect();

  return () => {
    redirect(resetPasswordUrl, true);
  };
};
