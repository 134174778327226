import { makeStyles, tokens } from "@fluentui/react-components";

export const useIdentityBannerStylesFluent = makeStyles({
  idBannerStyles: {
    marginBottom: tokens.spacingVerticalL,
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
});

export type IdentityBannerStylesDefaultFluent = ReturnType<typeof useIdentityBannerStylesFluent>;
