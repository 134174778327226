import React from "react";
import { FlowId, ViewId } from "@msidentity/SISU/constants";
import { AgreementViewFluent } from "@msidentity/SISU/views";
import { PasswordEntryViewConnectorFluent } from "../../password-entry/fluent/password-entry-view-connector-fluent";
import { PhoneDisambiguationViewConnectorFluent } from "../../phone-disambiguation/fluent/phone-disambiguation-view-connector-fluent";
import { UsernameEntryViewConnectorFluent } from "../../username-entry/fluent/username-entry-view-connector-fluent";
import { useInitialViewPickerFluent } from "./model/use-initial-view-picker-fluent";

/**
 * The view ID returned by the LoginStateToViewIdMap map is used by the initial view picker to choose the
 * corresponding View Connector Component from this map. Please ensure that this map is complete, i.e. it contains
 * an entry for every view ID in the LoginStateToViewIdMap map where appropriate.
 */
export const ViewIdToViewMapFluent: { [key: string]: JSX.Element } = {
  [ViewId.Username]: <UsernameEntryViewConnectorFluent />,
  [ViewId.PhoneDisambiguation]: <PhoneDisambiguationViewConnectorFluent />,
  [ViewId.ViewAgreement]: <AgreementViewFluent hostingFlow={FlowId.Login} />,
  [ViewId.Password]: <PasswordEntryViewConnectorFluent />,
  /* TODO: Uncomment once implemented
    [ViewId.OneTimeCode]: ,
    [ViewId.PushNotifications]: ,
    [ViewId.IdpDisambiguation]: ,
    [ViewId.IdpRedirect]: ,
    [ViewId.AccountPicker]: ,
    [ViewId.ConfirmSend]: ,
    [ViewId.CredentialPicker]: ,
    [ViewId.Error]: ,
    [ViewId.IdpRedirectSpeedbump]: ,
    [ViewId.ProofConfirmation]: , */
};

/**
 * Inital view picker connector for the login flow
 * @returns The first view instance for the login flow
 */
export const InitialViewPickerConnectorFluent: React.FC =
  function InitialViewPickerConnectorFluent() {
    const { initialViewId, readyToRender, shouldRedirect, showRemoteConnectCanaryValidationView } =
      useInitialViewPickerFluent();

    if (!readyToRender) {
      return <div data-testid="initial-view-placeholder" />;
    }

    if (shouldRedirect) {
      return <div data-testid="initial-view-redirect-placeholder" />;
    }

    if (showRemoteConnectCanaryValidationView) {
      return <UsernameEntryViewConnectorFluent />;
      // TODO uncomment once implemented <RemoteConnectCanaryValidationViewConnectorFluent nextView={initialViewId} />;
    }

    // We did not need to do canary validation, we can return the initial evaluated view
    return ViewIdToViewMapFluent[initialViewId] || <UsernameEntryViewConnectorFluent />;
  };
