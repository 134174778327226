import { ConfigWrapper } from "../../../utilities/config-wrapper";

export const agreementViewStringsFluent = {
  generalVisitLink: getLocalString("General_Visit_Link"),
  genericErrorNoResponseTryAgain: getLocalString("Generic_Error_NoResponse_TryAgain"),
  generalClose: getLocalString("General_Close"),
};

export type AgreementViewStringsFluent = typeof agreementViewStringsFluent;

// eslint-disable-next-line deprecation/deprecation
export const AgreementViewStringsConfigFluent = new ConfigWrapper({
  agreementViewStrings: agreementViewStringsFluent,
});
