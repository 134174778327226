import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const useSuggestionsStylesFluent = makeStyles({
  suggestionWrapperStyles: {
    ...shorthands.gap(tokens.spacingHorizontalM),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

export type SuggestionsStylesDefaultFluent = ReturnType<typeof useSuggestionsStylesFluent>;
