import React from "react";
import StylesConfigFluent from "../../../config/styles-config-fluent";
import EnvironmentBanner from "../environment-banner";

/**
 * EnvironmentBanner component
 * @returns Returns a div container the a different banner based on environment
 */
const EnvironmentBannerFluent: React.FC = function EnvironmentBannerFluent() {
  const envBannerStyles = StylesConfigFluent.instance.useEnvironmentBannerStyles();

  return <EnvironmentBanner styles={envBannerStyles} />;
};

export default EnvironmentBannerFluent;
