import React from "react";
import { Field, mergeClasses } from "@fluentui/react-components";
import StylesConfigFluent from "../../../../config/styles-config-fluent";
import { InputFluent } from "../../input/fluent/input-fluent";
import { PhoneCountryDropdownFluent } from "./phone-country-dropdown-fluent";
import { type PhoneNumberFluentProps } from "./phone-types-fluent";

/**
 * PhoneNumber component for Fluent
 * @param props props for the component
 * @returns A shared component that includes the phone country dropdown and phone number input field
 */
export const PhoneNumberFluent: React.FC<PhoneNumberFluentProps> = function PhoneNumberFluent(
  props,
) {
  const { dropdownProps, fieldProps, inputProps } = props;

  const {
    phoneNumberStyles,
    phoneNumberInputWrapperStyles,
    phoneNumberFieldStyles,
    phoneNumberInputStyles,
  } = StylesConfigFluent.instance.usePhoneNumberStyles();

  // Pass the validation state of the top-level field down to any field tied to any of the children (in this case,
  // just the field tied to the input element). Note that we do not pass the validation message since that is only
  // to be rendered by the top-level field element.
  const inputFieldProps = { validationState: fieldProps?.validationState };

  return (
    <Field {...fieldProps}>
      <div className={phoneNumberStyles}>
        <PhoneCountryDropdownFluent {...dropdownProps} />
        <InputFluent
          inputProps={{
            ...inputProps,
            wrapperStyleName: mergeClasses(
              phoneNumberInputWrapperStyles,
              inputProps.wrapperStyleName,
            ),
            fieldStyleName: mergeClasses(phoneNumberFieldStyles, inputProps.fieldStyleName),
            inputStyleName: mergeClasses(phoneNumberInputStyles, inputProps.inputStyleName),
          }}
          fieldProps={inputFieldProps}
        />
      </div>
    </Field>
  );
};
