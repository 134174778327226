import React from "react";
import {
  type LinkProps as RouterLinkProps,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { Link } from "@fluentui/react-components";
import { ViewId } from "../../../constants";
import GlobalConfig from "../../../global-config";
import { useGlobalContext } from "../../../global-context";
import { GlobalActionType } from "../../../global-reducer";
import { getAgreementViewRoute } from "../../../utilities/routing-helper";
import { type AgreementType } from "../../../views";

export const useCreateFooterLinkFluent = () => {
  const location = useLocation();
  const prevPath = location.pathname;

  const { isHosted } = GlobalConfig.instance;
  const { dispatchStateChange } = useGlobalContext();

  const createWebFooterLink = (url: string, text: string, styleName: string) => (
    <Link
      href={url}
      as="a"
      appearance="subtle"
      target="_blank"
      rel="noreferrer noopener"
      className={styleName}
    >
      {text}
    </Link>
  );

  const createHostedFooterLink = (
    agreementType: AgreementType,
    text: string,
    styleName: string,
  ) => (
    <Link appearance="subtle" className={styleName} as="button">
      {
        // This is a workaround supplied by the fluent team for wrapping react-router-dom's Link component within a fluent Link
        ((_Component: unknown, props: RouterLinkProps) => (
          <RouterLink
            {...props}
            to={getAgreementViewRoute(agreementType)}
            onClick={() =>
              dispatchStateChange({
                type: GlobalActionType.BeginNavigate,
                source: prevPath,
                destination: ViewId.ViewAgreement,
                displayOptions: { navigationDirection: "forward" },
              })
            }
          >
            {text}
          </RouterLink>
          // explicit any due to a bug in fluent types that requires casting the render function to any
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        )) as any
      }
    </Link>
  );

  const createFooterLinkFluent = (
    agreementType: AgreementType,
    url: string,
    text: string,
    styleName: string,
  ) =>
    isHosted
      ? createHostedFooterLink(agreementType, text, styleName)
      : createWebFooterLink(url, text, styleName);

  return createFooterLinkFluent;
};
