import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const useSelectDropdownStylesFluent = makeStyles({
  selectWrapperStyles: {
    position: "relative",
    width: "100%",
  },

  selectStyles: {
    lineHeight: tokens.lineHeightBase400,
    fontSize: tokens.fontSizeBase300,
    ...shorthands.padding(
      0,
      "44px" /* select padding (8px) + icon (24px) + icon padding (4px) + text padding (6px) */,
      0,
      "14px" /* select padding (8px) + text padding (6px) */,
    ),

    "& option:empty": {
      display: "none",
    },
  },

  selectPlaceholderLabelStyles: {
    display: "block",
    position: "absolute",
    left: tokens.spacingHorizontalS, // match the horizontal padding of the select
    cursor: "pointer",
    pointerEvents: "none", // enables the select to be clicked when the placeholder label is over it
  },
});

export type SelectDropdownStylesDefaultFluent = ReturnType<typeof useSelectDropdownStylesFluent>;
