import { MicrosoftConsumerHelpBaseUrl, MicrosoftConsumerHelpHrefUrl } from "../../constants";

export type FooterConfigOverrides = {
  urlHelpLinkDisplay?: string;
  urlHelpLink?: string;
};

export const footerConfigOverrides: FooterConfigOverrides = {
  urlHelpLinkDisplay: MicrosoftConsumerHelpBaseUrl, // TODO: Move to/create footer-config-overrides-msa.ts
  urlHelpLink: MicrosoftConsumerHelpHrefUrl, // TODO: Move to/create footer-config-overrides-msa.ts
};
