import { Flavors } from "../../../constants";
import {
  type FooterStringsFluent,
  defaultFooterStringsFluent,
  FooterStringsConfigFluent,
} from "./footer-strings-config-fluent";

/**
 * This function this maps a flavor (i.e Xbox, Fabric) to a set of flavored footer error strings.
 * @param flavor The active flavor.
 */
const footerStringsMapFluent = new Map<Flavors, FooterStringsFluent>([
  [Flavors.Fluent, defaultFooterStringsFluent],
]);

/**
 * Initialize Footer strings
 * @param flavor The active flavor.
 */
export function initFooterStringsFluent(flavor: Flavors) {
  FooterStringsConfigFluent.initialize({
    footerStrings: footerStringsMapFluent.get(flavor),
  });
}
