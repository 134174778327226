import { makeStyles, shorthands, tokens } from "@fluentui/react-components";
import {
  lightboxPaddingS,
  screenSize,
  touchPadding,
} from "../../../styles/fluent/constants-fluent.styles";

export const closeButtonFillColor = tokens.colorNeutralForeground3;
const debugDetailsInnerPadding = shorthands.padding(
  lightboxPaddingS,
  lightboxPaddingS,
  tokens.spacingVerticalL,
);

export const useDebugDetailsStylesFluent = makeStyles({
  // This is meant to be used on top of the lightbox layout "inner" styles
  debugDetailsLightboxStyles: {
    alignItems: "start",
    ...debugDetailsInnerPadding,
    marginTop: tokens.spacingVerticalS,
    [screenSize.medium]: {
      backgroundColor: tokens.colorNeutralBackground3,
      marginTop: 0,
      // needed to overwrite lightbox "inner" padding
      ...debugDetailsInnerPadding,

      [screenSize.small]: {
        // needed to overwrite lightbox "inner" padding
        ...debugDetailsInnerPadding,
      },
    },
  },

  debugDetailsTitleContainerStyles: {
    display: "flex",
    width: "100%",
    paddingBottom: tokens.spacingVerticalM,
  },

  debugDetailsTitleStyles: {
    ...shorthands.flex("auto"),
  },

  debugDetailsSectionPaddingStyles: {
    paddingBottom: touchPadding,
  },
});

export type DebugDetailsStylesDefaultFluent = ReturnType<typeof useDebugDetailsStylesFluent>;
