import React from "react";
import { PhoneNumberFluent } from "../../../components/inputs/phone-number/fluent/phone-number-fluent";
import LinkFluent from "../../../components/link/fluent/link-fluent";
import { ViewContainerFluent } from "../../../components/view-container/fluent/view-container-fluent";
import StylesConfigFluent from "../../../config/styles-config-fluent";
import { type PhoneDisambiguationViewRendererFluentProps } from "./model/phone-disambiguation-view-types-fluent";

/**
 * Fluent Phone Disambiguation view renderer
 * @param props The view props from the IDP
 * @param props.strings The required strings used in the view
 * @param props.handlers The link and button click handlers
 * @param props.phoneNumberProps The props for the phone number input
 * @returns A rendered instance of the Phone Disambiguation view
 */
export const PhoneDisambiguationViewRendererFluent: React.FC<PhoneDisambiguationViewRendererFluentProps> =
  function PhoneDisambiguationViewRendererFluent(
    props: PhoneDisambiguationViewRendererFluentProps,
  ) {
    const {
      strings: { title, subtitle, signInWithDifferentUserLinkLabel, primaryButtonLabel },
      handlers: { signInWithDifferentUserLinkHandler, onSubmitHandler },
      phoneNumberProps,
    } = props;

    const { sectionPaddingTopStyles, linkPaddingTopStyles } =
      StylesConfigFluent.instance.useCommonStyles();

    return (
      <ViewContainerFluent
        titleContainerProps={{
          titleProps: { text: title },
          subtitleProps: { text: subtitle },
        }}
        buttonContainerProps={{
          primaryButton: { buttonText: primaryButtonLabel },
        }}
        formProps={{ onSubmit: onSubmitHandler }}
      >
        <div className={sectionPaddingTopStyles}>
          <PhoneNumberFluent {...phoneNumberProps} />
          <div className={linkPaddingTopStyles}>
            <LinkFluent onClick={signInWithDifferentUserLinkHandler}>
              {signInWithDifferentUserLinkLabel}
            </LinkFluent>
          </div>
        </div>
      </ViewContainerFluent>
    );
  };
