import { usePasswordInputFluent } from "@msidentity/SISU/components/inputs/hooks/fluent/use-password-input-fluent";
import { AtLeastOneCharacterRegex } from "@msidentity/SISU/components/inputs/input-regex-constants";
import { FlowId, postType as PostType, ViewId } from "@msidentity/SISU/constants";
import { useShowBackButton } from "@msidentity/SISU/flows/login/hooks/login-hooks";
import { LoginState } from "@msidentity/SISU/flows/login/login-constants";
import type { LoginInputValidationErrorStrings } from "@msidentity/SISU/flows/login/login-interface";
import { usePasswordHandler } from "@msidentity/SISU/flows/login/views/password/hooks/use-password-handler";
import GlobalConfig from "@msidentity/SISU/global-config";
import { useActivateViewWithDocumentTitle } from "@msidentity/SISU/hooks";
import { simpleInputValidation } from "@msidentity/SISU/utilities/input-helper";
import { type PasswordEntryViewRendererFluentProps } from "@msidentity/SISU/views/password-entry/fluent/model/password-entry-view-types-fluent";
import { useLoginPostProps } from "../../../model/use-login-post-props";
import { useServerDataErrorFluent } from "../../../model/use-server-data-error-fluent";
import { useForgotPasswordLinkHandler } from "../../model/use-forgot-password-link-handler";
import type { PasswordEntryViewStringsFluent } from "./password-entry-view-strings-fluent";

/**
 * Hook that creates the validationMethod and inputStatefor the password entry view input on Fluent login.
 * This is intended to be used in combination with the usePasswordEntryViewFluent hook below.
 * @param viewStrings The password entry view strings
 * @param loginErrorStrings The strings for login server data errors
 * @returns The state for the password input
 */
export const usePasswordEntryInputFluent = (
  viewStrings: PasswordEntryViewStringsFluent,
  loginErrorStrings: LoginInputValidationErrorStrings,
) => {
  const { errorStringEmptyPassword } = viewStrings;

  const validationMethod = simpleInputValidation(
    errorStringEmptyPassword,
    AtLeastOneCharacterRegex,
    errorStringEmptyPassword,
  );

  const { serverError } = useServerDataErrorFluent({
    strings: loginErrorStrings,
  });

  const passwordState = usePasswordInputFluent({
    validationMethod,
    initialServerError: serverError,
  });

  return passwordState;
};

/**
 * Aggregator hook for fluent password entry.
 * @param passwordEntryStringsFluent The password entry view strings
 * @param loginInputValidationErrorStrings The strings for login server data errors
 * @returns Props needed by the renderer of type PasswordEntryViewRendererFluentProps.
 */
export const usePasswordEntryViewFluent = (
  passwordEntryStringsFluent: PasswordEntryViewStringsFluent,
  loginInputValidationErrorStrings: LoginInputValidationErrorStrings,
): PasswordEntryViewRendererFluentProps => {
  const { title } = passwordEntryStringsFluent;
  const { postUrl } = GlobalConfig.instance;

  const showBackButton = useShowBackButton();
  useActivateViewWithDocumentTitle(title, ViewId.Password, FlowId.Login, {
    showBackButtonOnActiveView: showBackButton,
  });

  const forgotPasswordLinkHandler = useForgotPasswordLinkHandler();

  const passwordState = usePasswordEntryInputFluent(
    passwordEntryStringsFluent,
    loginInputValidationErrorStrings,
  );

  const passwordProps = {
    ...passwordState,
    inputProps: {
      ...passwordState.inputProps,
      name: "passwd",
      autoComplete: "current-password",
    },
    strings: {
      placeholder: passwordEntryStringsFluent.placeholder,
    },
  };

  const onSubmitHandler = usePasswordHandler(postUrl, passwordState.inputState);

  const loginPostProps = useLoginPostProps(LoginState.Password, PostType.password);
  const formProps = {
    dataTestId: "passwordEntryForm",
    action: postUrl,
  };

  return {
    strings: passwordEntryStringsFluent,
    handlers: {
      forgotPasswordLinkHandler,
      onSubmitHandler,
    },
    passwordProps,
    loginPostProps,
    formProps,
  };
};
