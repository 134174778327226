import React, { useContext, useState } from "react";
import LoginConfig from "@msidentity/SISU/flows/login/login-config";
import { useLoginContext } from "@msidentity/SISU/flows/login/login-context";
import { LoginActionType } from "@msidentity/SISU/flows/login/login-reducer";
import {
  type LoginGetServerErrorTextProps,
  getServerErrorText,
} from "@msidentity/SISU/flows/login/login-util";
import { GlobalContext } from "@msidentity/SISU/global-context";
import { useEffectOnce } from "@msidentity/SISU/hooks";
import {
  createEmbeddedLinkFluent,
  FormattedTextWithBindingsFluent,
} from "@msidentity/SISU/utilities/formatted-text-with-bindings";

/**
 * This hook is used to determine if a ServerData error was provided from an IDP and hasn't been displayed to the user yet.
 * An effect is used to update the LoginContext to note that the error has been displayed so it does not get displayed again.
 * @param props LoginGetServerErrorTextProps
 * @returns An object containing the following properties:
 * - `showServerError`: An indicator that the error message should be shown to the user in the consuming view.
 * - `serverError`: The JSX element or empty string of the error message to display. This will be an empty string if `showServerError` is false.
 */
export const useServerDataErrorFluent = (
  props: Omit<LoginGetServerErrorTextProps, "errorCode">,
) => {
  const {
    globalState: {
      debugInfo: { errorCode },
    },
  } = useContext(GlobalContext);
  const { fedUrl } = LoginConfig.instance;
  const { strings } = props;

  const {
    viewState: { serverErrorShown },
    dispatchStateChange: dispatchLoginStateChange,
  } = useLoginContext();

  const errorText = getServerErrorText({ strings, errorCode });
  const viewShouldShowError = !!errorText && !serverErrorShown;

  const embeddedBindingForFedError = createEmbeddedLinkFluent({ url: fedUrl });
  let formattedError: string | JSX.Element = "";

  if (viewShouldShowError) {
    formattedError = (
      <FormattedTextWithBindingsFluent
        text={errorText}
        embeddedBindings={{ federatedLink: embeddedBindingForFedError }}
      />
    );
  }

  // Cache these values so the hook doesn't return different values on subsequent renders.
  // It might not be clear to consumers that the initial return value needs to be cached, so we'll do it internally.
  const [showServerError] = useState(viewShouldShowError);
  const [serverError] = useState(formattedError);

  useEffectOnce(() => {
    if (showServerError) {
      dispatchLoginStateChange({
        type: LoginActionType.SetServerErrorShown,
        payload: true,
      });
    }
  });

  return {
    showServerError,
    serverError,
  };
};

/**
 * Hook to set the server data error on a view using the supplied method to update the error string.
 * @param props LoginGetServerErrorTextProps
 * @param setError method to set the error string
 */
export const useSetServerDataErrorOnViewFluent = (
  props: Omit<LoginGetServerErrorTextProps, "errorCode">,
  setError: (error: string | JSX.Element) => void,
) => {
  const { showServerError, serverError } = useServerDataErrorFluent(props);

  useEffectOnce(() => {
    if (showServerError) {
      setError(serverError);
    }
  });
};
