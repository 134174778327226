import { initDebugDetailsStringsFluent } from "../../../components/debug-details/fluent/init-debug-details-strings-fluent";
import { initFooterStringsFluent } from "../../../components/footer/fluent/init-footer-strings-fluent";
import { type FooterConfigOverrides } from "../../../components/footer/footer-config-overrides";
import { initStylesConfigFluent } from "../../../config/styles-config-fluent";
import { type Flavors, type FlowId } from "../../../constants";
import { createGlobalState } from "../../../global-context";
import { type UserOptions } from "../../../global-reducer";
import { initialHistoryState } from "../../../history-context";
import { type ServerData } from "../../../utilities/server-data";
import { initAgreementViewStringsFluent } from "../../../views/agreement-view/fluent/init-agreement-view-strings-fluent";
import { defaultFluentThemeState } from "../../theme/fluent/fluent-theme-context";
import { getInitialUser } from "../get-initial-user";
import { initBaseSisuConfig } from "../init-base-sisu-config";
import { type BaseSisuStateFluent } from "./base-sisu-providers-fluent";

/**
 * Parse the ServerData object sent from the IDP and return the initial config/state for the common app context and config
 * for fabric-based flavors.
 * IMPORTANT: This should be called at module scope, NOT inside the render function!!
 * @param serverData The IDP-specific ServerData object sent from the server and read from the window object.
 * @param activeFlavor Rendered flavor
 * @param activeFlow Rendered flow
 * @param getUserOptions Opptional method to get the user for scenarios with additional requirements.
 * @param footerOverrides Optional overrides for the footer URLs.
 * @returns The initial state for the global, history, and customization contexts.
 */
export const initBaseSisuAppDataFluent = (
  serverData: ServerData,
  activeFlavor: Flavors,
  activeFlow: FlowId,
  getUserOptions?: () => UserOptions,
  footerOverrides?: FooterConfigOverrides,
): BaseSisuStateFluent => {
  initBaseSisuConfig(serverData, activeFlavor, true, footerOverrides);

  initDebugDetailsStringsFluent(activeFlavor);
  initStylesConfigFluent(activeFlavor);
  initAgreementViewStringsFluent(activeFlavor);
  initFooterStringsFluent(activeFlavor);

  const initialGlobalState = createGlobalState(
    serverData,
    getInitialUser(getUserOptions),
    activeFlow,
  );

  return {
    initialGlobalState,
    initialHistoryState,
    initialFluentThemeState: defaultFluentThemeState,
  };
};
