import { makeStaticStyles, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import {
  defaultBackgroundColor,
  defaultBodyTextColor,
  defaultImagePadding,
  lightboxBackgroundColor,
  screenSize,
  sectionPadding,
} from "./constants-fluent.styles";

export const useStaticCommonStylesFluent = makeStaticStyles({
  "*": {
    boxSizing: "border-box",
  },
});

const useCommonStylesFluent = makeStyles({
  defaultTextColorStyles: {
    color: defaultBodyTextColor,
  },

  displayNoneStyles: {
    display: "none",
  },

  hiddenStyles: {
    visibility: "hidden",
  },

  imagePaddingStyles: {
    ...shorthands.padding(defaultImagePadding, 0),
  },

  otcDisplayStyles: {
    fontSize: tokens.fontSizeBase600,
    letterSpacing: "2px",
  },

  linkStyles: {
    fontWeight: tokens.fontWeightSemibold,
  },

  outer: {
    width: "100%",
    display: "grid",
    position: "absolute",
    height: "100vh", // vh required for Firefox
    ...shorthands.inset(0),
    gridTemplateRows: "1fr auto",
    backgroundColor: defaultBackgroundColor,

    [screenSize.medium]: {
      backgroundColor: lightboxBackgroundColor,
    },
  },

  sectionPaddingBottomStyles: {
    paddingBottom: sectionPadding,
  },

  sectionPaddingTopStyles: {
    paddingTop: sectionPadding,
  },

  textAlignCenterStyles: {
    textAlign: "center",
  },

  wordBreakStyles: {
    wordBreak: "break-word",
  },

  centerHeightStyles: {
    height: "100%",
  },

  flexRowStyles: {
    display: "flex",
    flexDirection: "row",
    columnGap: tokens.spacingHorizontalM,
  },

  defaultErrorColorStyles: {
    color: tokens.colorPaletteRedForeground1,
  },

  flexColumnStyles: {
    display: "flex",
    flexDirection: "column",
    rowGap: tokens.spacingVerticalXXL,
  },

  flexAlignCenterStyles: {
    alignItems: "center",
  },

  linkPaddingTopStyles: {
    marginTop: tokens.spacingVerticalS,
  },

  mediumPaddingTopStyles: {
    marginTop: tokens.spacingVerticalM,
  },
});

export default useCommonStylesFluent;

export type CommonStylesDefaultFluent = ReturnType<typeof useCommonStylesFluent>;
