import { makeStyles, tokens } from "@fluentui/react-components";

export const usePhoneNumberStylesFluent = makeStyles({
  phoneNumberStyles: {
    display: "flex",
    width: "100%",
    columnGap: tokens.spacingHorizontalM,
  },

  phoneNumberInputWrapperStyles: {
    marginTop: 0,
  },

  phoneNumberFieldStyles: {
    width: "100%",
  },

  phoneNumberInputStyles: {
    "& input": {
      width: "100%",
    },
  },
});

export default usePhoneNumberStylesFluent;

export type PhoneNumberStylesDefaultFluent = ReturnType<typeof usePhoneNumberStylesFluent>;
