import React from "react";
import { type LinkProps, Link } from "@fluentui/react-components";
import StylesConfigFluent from "../../../config/styles-config-fluent";
import { useGlobalContext } from "../../../global-context";
import {
  type ProgressIndicatorPropsFluent,
  ProgressIndicatorOnScreenFluent,
} from "../../loading-progress/fluent/progress-indicator-on-screen-fluent";

export type LinkFluentProps = LinkProps & {
  progressIndicatorProps?: ProgressIndicatorPropsFluent;
};

/**
 * @param props LinkProps
 * @param props.progressIndicatorProps Props for the progress indicator spinner to replace the link.
 * If passed in, we will show the spinner during progress. Otherwise, we won't show it.
 * @returns a componant that applies custom formatting to the base fluent Link component
 * and disallows setting the inline property.
 */
const LinkFluent: React.FC<LinkFluentProps> = function LinkFluent(props) {
  const {
    globalState: { showProgressIndicator },
  } = useGlobalContext();

  const { linkStyles } = StylesConfigFluent.instance.useCommonStyles();

  const noInlineProps = { ...props, inline: undefined };

  // Only show the progress indicator if it is explicitly enabled. Disabled by default.
  const { progressIndicatorProps } = props;
  const isLoading = showProgressIndicator && !!progressIndicatorProps;

  return isLoading ? (
    <ProgressIndicatorOnScreenFluent {...progressIndicatorProps} />
  ) : (
    <Link {...noInlineProps} className={linkStyles} />
  );
};

export default LinkFluent;
