import { makeStyles, shorthands } from "@fluentui/react-components";

export const useEnvironmentBannerStylesFluent = makeStyles({
  envBanner: {
    display: "table",
    maxWidth: "200px",
    minHeight: "50px",
    maxHeight: "100px",
    ...shorthands.overflow("hidden"),
    backgroundColor: "#0067B8",
    color: "#FFFFFF",
    position: "absolute",
    ...shorthands.margin("10px"),
    fontWeight: "bold",
    top: 0,
    right: 0,
    zIndex: 100,
  },
  envBannerInner: {
    display: "table-cell",
    textAlign: "center",
    ...shorthands.padding("5px"),
    fontSize: "16px",
    direction: "ltr",
    "& a": {
      textDecorationLine: "underline",
      color: "#FFF",
    },
  },
  envBannerDescription: {
    fontSize: "14px",
    paddingTop: "5px",
    marginTop: "5px",
    width: "100%",
    textAlign: "center",
    ...shorthands.borderTop("1px", "solid", "white"),
  },
});

export type EnvironmentBannerStylesDefaultFluent = ReturnType<
  typeof useEnvironmentBannerStylesFluent
>;
