import { useCallback, useEffect, useState } from "react";

/**
 * A custom hook that returns a boolean indicating whether the given media query matches the device.
 * @param query - The media query string to test.
 * @returns A boolean indicating whether the given media query matches the device.
 */
export function useMatchMediaQuery(query: string): boolean {
  /**
   * Gets a boolean indicating whether the given media query matches the device.
   * @param queryString - The media query string to test.
   * @returns A boolean indicating whether the given media query matches the device.
   */
  const getMatches = (queryString: string): boolean => {
    // Prevents SSR issues
    if (typeof window !== "undefined") {
      return window.matchMedia(queryString).matches;
    }

    return false;
  };

  const [matches, setMatches] = useState<boolean>(getMatches(query));

  /**
   * Handles the change event of the media query and updates the state of the hook.
   */
  const handleChange = useCallback(() => {
    setMatches(getMatches(query));
  }, [query]);

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    // Triggered at the first client-side load and if query changes
    handleChange();

    // Listen to matchMedia
    if (matchMedia.addEventListener) {
      matchMedia.addEventListener("change", handleChange);
    } else {
      // eslint-disable-next-line deprecation/deprecation
      matchMedia.addListener(handleChange);
    }

    return () => {
      if (matchMedia.removeEventListener) {
        matchMedia.removeEventListener("change", handleChange);
      } else {
        // eslint-disable-next-line deprecation/deprecation
        matchMedia.removeListener(handleChange);
      }
    };
  }, [handleChange, query]);

  return matches;
}
