import { useEffect, useState } from "react";
import { type Location, useLocation } from "react-router-dom";
import { mergeClasses } from "@fluentui/react-components";
import StylesConfigFluent from "../../../../config/styles-config-fluent";

enum RouteAnimationStage {
  FadeIn,
  FadeOut,
}

export type RouteAnimationResponseFluent = {
  currentLocation: Location;
  animationStyles: string;
  onAnimationEnd: () => void;
};

/**
 * React hook used for applying animations based on location change.
 * @returns An array with the current location, animation styles, and animation end event callback.
 */
export const useRouteAnimationFluent = (): RouteAnimationResponseFluent => {
  const { fadeInStyles, fadeOutStyles, animateStyles, routeAnimationContainerStyles } =
    StylesConfigFluent.instance.useAnimationStyles();
  const fadeOutAnimation = mergeClasses(
    routeAnimationContainerStyles,
    animateStyles,
    fadeOutStyles,
  );
  const fadeInAnimation = mergeClasses(routeAnimationContainerStyles, animateStyles, fadeInStyles);
  const newLocation = useLocation();
  const [currentLocation, setCurrentLocation] = useState(newLocation);
  const [animationStage, setAnimationStage] = useState(RouteAnimationStage.FadeIn);
  const [animationStyles, setAnimationStyles] = useState(fadeInAnimation);

  useEffect(() => {
    // when location changes, fade out the current view
    if (
      newLocation.pathname !== currentLocation.pathname &&
      animationStage === RouteAnimationStage.FadeIn
    ) {
      setAnimationStage(RouteAnimationStage.FadeOut);
      setAnimationStyles(fadeOutAnimation);
    }
  }, [newLocation, currentLocation, animationStage, fadeOutAnimation]);

  const onAnimationEnd = () => {
    // once the fade out animation is complete, fade in the new view
    if (animationStage === RouteAnimationStage.FadeOut) {
      setAnimationStage(RouteAnimationStage.FadeIn);
      setAnimationStyles(fadeInAnimation);
      setCurrentLocation(newLocation);
    }
  };

  return { currentLocation, animationStyles, onAnimationEnd };
};
