import React from "react";
import DebugDetailsFluent from "../../debug-details/fluent/debug-details-fluent";

export type LayoutContentStylesFluent = {
  innerClassName: string;
  showDebugDetails: boolean;
  onDebugDetailsCloseClick: () => void;
};

/**
 * @param props Children to pass into layout
 * @returns LayoutContent component containing the shared components/HTML for the layout
 */
const LayoutContentFluent: React.FC<LayoutContentStylesFluent> = function LayoutContentFluent(
  props,
) {
  const { children, innerClassName, showDebugDetails, onDebugDetailsCloseClick } = props;

  return (
    <>
      <div id="inner" data-testid="inner" className={innerClassName}>
        {children /* ViewContainer */}
      </div>
      {showDebugDetails && <DebugDetailsFluent onCloseClick={onDebugDetailsCloseClick} />}
    </>
  );
};

export default LayoutContentFluent;
