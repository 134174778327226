import { makeStyles, shorthands } from "@fluentui/react-components";

export const useArkoseEnforcementStylesFluent = makeStyles({
  iframe: {
    width: "0px",
    minHeight: "500px",
    ...shorthands.border("0"),
    OverflowY: "hidden",
    display: "block",
    ...shorthands.margin("auto"),
  },
});

export type ArkoseEnforcementStylesDefaultFluent = ReturnType<
  typeof useArkoseEnforcementStylesFluent
>;
