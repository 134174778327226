import { makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";

export const iconButtonFillColor = tokens.colorNeutralForeground3;

export const useButtonStylesFluent = makeStyles({
  viewButtonContainerStyles: {
    display: "flex",
    flexDirection: "column",
    rowGap: tokens.spacingVerticalM,
  },

  viewButtonStyles: {
    ...typographyStyles.body1Strong,
    display: "flex",
    ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalL),
    justifyContent: "center",
    maxWidth: "100%",
  },
});

export type ButtonStylesDefaultFluent = ReturnType<typeof useButtonStylesFluent>;
