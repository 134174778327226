import type React from "react";
import { useRef, useState } from "react";
import { type PasswordFluentProps } from "../../password/fluent/password-fluent";

/**
 * This hook is used to control the "password reveal" functionality of the PasswordFluent component.
 * The logic is kept separate from the component to allow for easier testing and reusability.
 * @param props The properties provided
 * @returns The properties and functions needed to control the password reveal functionality
 */
export const usePasswordRevealFluent = (
  props: Pick<PasswordFluentProps, "inputProps" | "inputState">,
) => {
  const {
    inputProps,
    inputState: { value },
  } = props;
  const { onBlur: markInputBlurred, ref: inputRef } = inputProps;

  const buttonRef = useRef(null);

  const [selectionRange, setSelectionRange] = useState<(number | null)[]>([null, null]);

  const [passwordRevealed, setPasswordRevealed] = useState(false);
  const inputType: "text" | "password" = passwordRevealed ? "text" : "password";

  const onClickButton = () => {
    // Toggle the password being revealed
    setPasswordRevealed(!passwordRevealed);
    // Once the button is clicked, we'll focus back on the input and reapply the same selection range (cursor position)
    setTimeout(() => {
      if (inputRef?.current) {
        inputRef.current.focus();
        const [selectionStart, selectionEnd] = selectionRange;
        inputRef.current.setSelectionRange(selectionStart, selectionEnd);
      }
    });
  };

  const onBlurInput: React.FocusEventHandler<HTMLInputElement> = (event) => {
    // If the target stealing focus is the button, we'll do nothing but note the selection range to use on the button's click event
    // That event will focus back on the input and reapply the same selection range (cursor position)
    if (event.relatedTarget === buttonRef.current) {
      const { selectionStart, selectionEnd } = event.target;
      setSelectionRange([selectionStart, selectionEnd]);
    }
    // If the target stealing focus is not the button, we'll mark the input as blurred and hide the password
    // Then we'll mark the selection range as the end of the value so that the cursor is at the end of the input
    // if a user hovers over the reveal button and clicks it when the input isn't focused
    else {
      markInputBlurred();
      setPasswordRevealed(false);
      const endOfValue = String(value).length;
      setSelectionRange([endOfValue, endOfValue]);
    }
  };

  return {
    buttonRef,
    inputType,
    onClickButton,
    onBlurInput,
    passwordRevealed,
  };
};
