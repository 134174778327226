import React, { useState } from "react";
import { type DropdownProps } from "@fluentui/react-components";
import StylesConfigFluent from "../../../../config/styles-config-fluent";
import { DropdownFluent } from "../../dropdown/fluent/dropdown-fluent";
import { createCountryMap } from "../phone-utils";
import { type PhoneNumberDropdownPropsFluent } from "./phone-types-fluent";

export type DropdownSelectHandler = Exclude<DropdownProps["onOptionSelect"], undefined>;

/**
 * PhoneCountryDropdown component for Fluent
 * @param props The properties for this component.
 * @param props.defaultCountryData The default country information.
 * @param props.countryData The array of country information.
 * @param props.hasError Whether the country code dropdown is showing error.
 * @param props.hasInitialFocus Whether the country code dropdown is focused by default.
 * @param props.hasFocus Whether the country code dropdown is currently focused.
 * @param props.onChange The value change callback. Enables the external component to do something with the selected country value.
 * @returns A dropdown component with a list of phone country codes.
 */
export const PhoneCountryDropdownFluent: React.FC<PhoneNumberDropdownPropsFluent> =
  function PhoneCountryDropdownFluent(props) {
    const { value, countryData, onChange = () => {} } = props;

    const { phoneCountrySelectStyles, listboxStyles } =
      StylesConfigFluent.instance.usePhoneCountryDropdownStyles();

    const countryMap = createCountryMap(countryData);

    // The truncatedSelectedValueText is used to display the country code when the dropdown option selected (e.g., +1).
    const [truncatedSelectedValueText, setTruncatedSelectedValueText] = useState(
      `\u200E+${value.code}`,
    );

    const handleDropdownValueChange: DropdownSelectHandler = (_event, data) => {
      if (data.optionValue && data.optionText) {
        const currentCountry = countryMap[data.optionValue];
        onChange(currentCountry);
        setTruncatedSelectedValueText(`\u200E+${currentCountry.code}`);
      }
    };

    return (
      <DropdownFluent
        id="phoneCountry"
        name="PhoneCountry"
        aria-label={getLocalString("General_CountryCode")}
        defaultValue={`\u200E+${value.code}`}
        truncatedSelectedValueTextOptions={{ selectedValueText: truncatedSelectedValueText }}
        defaultSelectedOptions={[value.iso]}
        onOptionSelect={handleDropdownValueChange}
        dropdownStyleName={phoneCountrySelectStyles}
        listboxStyleName={listboxStyles}
        selectOptions={countryData.map((country) => ({
          optionText: country.displayValue,
          value: country.iso,
          displayText: country.displayValue, // The displayText value (e.g., United States (+1)) is used as the comparison for type-to-find keyboard functionality.
        }))}
      />
    );
  };
