import { type PhoneDisambiguationViewRendererStringsFluent } from "@msidentity/SISU/views/phone-disambiguation";

export type PhoneDisambiguationViewStringsFluent = PhoneDisambiguationViewRendererStringsFluent & {
  invalidPhoneFormatError: string;
  placeholder: string;
};

export const phoneDisambiguationViewStringsFluent: PhoneDisambiguationViewStringsFluent = {
  invalidPhoneFormatError: getLocalString("Login_PhoneNumber_ForbiddenCharacters_Error"),
  placeholder: getLocalString("General_PhoneNumber"),
  primaryButtonLabel: getLocalString("General_Buttons_Next"),
  signInWithDifferentUserLinkLabel: getLocalString("SigninWithDifferentUsername"),
  subtitle: getLocalString("SearchForAccountWithUsername"),
  title: getLocalString("SelectYourCountryCode"),
};
