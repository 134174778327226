import React from "react";
import { mergeClasses, Text } from "@fluentui/react-components";
import FooterConfig from "../../../components/footer/footer-config";
import { ViewContainerFluent } from "../../../components/view-container/fluent/view-container-fluent";
import StylesConfigFluent from "../../../config/styles-config-fluent";
import { type AgreementViewProps, AgreementType } from "../agreement-view-interface";
import { hasChildStyling, isExternalAgreementLink } from "../agreement-view-util";
import { useAgreementView } from "../use-agreement-view";
import { AgreementViewStringsConfigFluent } from "./agreement-view-strings-config-fluent";

/**
 * A shared view that displays the agreement text for hosted screens. Accessed via a link on the lightbox-footer component.
 * @param props The props for this component
 * @param props.hostingFlow The flow that this agreement view is hosted in
 * @returns A rendered instance of this shared view
 */
export const AgreementViewFluent: React.FC<AgreementViewProps> = function AgreementViewFluent({
  hostingFlow,
}) {
  const { footerImpressumUrl, footerA11yConformeUrl, privacyUrl, termsOfUseUrl } =
    FooterConfig.instance;

  const { defaultTextColorStyles, wordBreakStyles } = StylesConfigFluent.instance.useCommonStyles();
  const { childStyling, chinaPipl } = StylesConfigFluent.instance.useAgreementViewStyles();

  const {
    agreementViewStrings: { generalVisitLink, genericErrorNoResponseTryAgain, generalClose },
  } = AgreementViewStringsConfigFluent.instance;

  const { agreementType, downloadError, agreementHtml, onBack } = useAgreementView({
    footerA11yConformeUrl,
    footerImpressumUrl,
    generalVisitLink,
    hostingFlow,
    privacyUrl,
    termsOfUseUrl,
    isWideAgreementView: (type) => !isExternalAgreementLink(type),
    showIdentityBanner: true,
  });

  // TODO: Reset focus on the button. This is necessary because the agreements share the same route via the react-router link component.
  // If a user navigates between agreements or within same agreement, react-router does not unmount the component and mount a new instance.

  // Known behaviors when clicking between agreements:
  //  - Each view is added to the history stack, so the back button will navigate through them before reaching the original view.
  //  - When moving from a wide view to narrow view or vice versa, only the original width will be honored for each subsequent view,
  //    regardless of the view's individual setting.
  return (
    <ViewContainerFluent
      buttonContainerProps={{ primaryButton: { buttonText: generalClose, onClick: onBack } }}
    >
      <div>
        {downloadError && (
          <Text
            className={defaultTextColorStyles}
            data-testid="error"
            role="alert"
            aria-live="assertive"
            aria-relevant="text"
            aria-atomic="true"
          >
            {genericErrorNoResponseTryAgain}
          </Text>
        )}
        {agreementHtml && (
          <div
            className={mergeClasses(
              defaultTextColorStyles,
              wordBreakStyles,
              hasChildStyling(agreementType) ? childStyling : "",
              agreementType === AgreementType.ChinaPiplLearnMore ? chinaPipl : "",
            )}
            data-testid="agreement"
            role="alert"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: agreementHtml }}
          />
        )}
      </div>
    </ViewContainerFluent>
  );
};
