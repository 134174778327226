import { type BrandVariants, type ColorTokens } from "@fluentui/react-components";

export enum FluentCobrandingThemeLock {
  DarkModeOnly = "DarkModeOnly",
  LightModeOnly = "LightModeOnly",
}

export type FluentCobrandingProps = {
  appFriendlyName?: string;
  isGaming?: boolean; // TODO: should this be set here or read from server data?
  themeLock?: FluentCobrandingThemeLock;
  lightTheme?: FluentCobrandingTheme;
  darkTheme?: FluentCobrandingTheme;
};

export type FluentCobrandingTheme = {
  themeColorRamp: BrandVariants;
  themeColorOverrides?: Partial<ColorTokens>;
};
