import { makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";
import {
  defaultImagePadding,
  screenSize,
  sectionPadding,
  touchPadding,
  viewContainerMinWidth,
} from "../../../styles/fluent/constants-fluent.styles";

export const useViewContainerStylesFluent = makeStyles({
  captionStyles: typographyStyles.caption1,

  captionWrapperStyles: {
    ...shorthands.padding(sectionPadding, 0, touchPadding),
  },

  imageWrapperStyles: {
    textAlign: "center",
    ...shorthands.padding(defaultImagePadding, 0),
  },

  secondaryDescriptionStyles: {
    paddingTop: tokens.spacingVerticalXXL,
  },

  secondaryCaptionWrapperStyles: {
    ...shorthands.padding(0, 0, touchPadding),
  },

  viewContainerStyles: {
    width: "100%",

    [screenSize.medium]: {
      minWidth: viewContainerMinWidth,

      [screenSize.small]: {
        maxWidth: "100%",
      },
    },
  },

  formStyles: {
    width: "100%",
  },

  loadingScreenStyles: {
    height: "360px",
  },
});

export type ViewContainerStylesDefaultFluent = ReturnType<typeof useViewContainerStylesFluent>;
