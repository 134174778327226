import React from "react";
import { Link } from "@fluentui/react-components";
import StylesConfigFluent from "../../../config/styles-config-fluent";
import { PrivateBrowsingLearnMoreUrl } from "../../../constants";
import GlobalConfig from "../../../global-config";
import { FormattedTextWithBindingsFluent } from "../../../utilities/formatted-text-with-bindings";
import { createEmbeddedLinkFluent } from "../../../utilities/formatted-text-with-bindings/fluent/formatted-text-helpers-fluent";
import { AgreementType } from "../../../views";
import FooterConfig from "../footer-config";
import { FooterStringsConfigFluent } from "./footer-strings-config-fluent";
import { useCreateFooterLinkFluent } from "./use-create-footer-link-fluent";

export type FooterFluentProps = {
  isDebugDetailsExpanded: boolean;
  onHelpLinkClick: () => void;
  helpLinkRef: React.RefObject<HTMLButtonElement>;
  footerContainerRef?: React.RefObject<HTMLDivElement>;
};

/**
 * Page footer component
 * @param props FooterFluentProps
 * props.onHelpLinkClick: Callback to execute when the help link is clicked
 * props.helpLinkRef: Ref to the help link button
 * @returns the footer for the page containing links to terms of use, privacy policy, and other legal information
 */
const FooterFluent: React.FC<FooterFluentProps> = function FooterFluent(props) {
  const { onHelpLinkClick, helpLinkRef, isDebugDetailsExpanded, footerContainerRef } = props;

  const { footerImpressumUrl, footerA11yConformeUrl, helpUrl, privacyUrl, termsOfUseUrl } =
    FooterConfig.instance;

  const {
    footerLinkStyles,
    footerStyles,
    footerContainerStyles,
    footerSectionStyles,
    privateBrowsingTextStyles,
  } = StylesConfigFluent.instance.useFooterStyles();

  const {
    footerStrings: {
      helpLinkText,
      touLinkText,
      privacyLinkText,
      impressumLinkText,
      a11yConformanceLinkText,
      privateBrowsingLinkText,
    },
  } = FooterStringsConfigFluent.instance;

  const { isHosted } = GlobalConfig.instance;

  const createFooterLinkFluent = useCreateFooterLinkFluent();

  return (
    <div className={footerContainerStyles} ref={footerContainerRef} data-testid="footer">
      <footer className={footerStyles}>
        <div className={footerSectionStyles}>
          {/* clicking this should open debug details. */}
          {helpUrl && (
            <Link
              ref={helpLinkRef}
              as="button"
              appearance="subtle"
              className={footerLinkStyles}
              onClick={onHelpLinkClick}
              aria-expanded={isDebugDetailsExpanded}
            >
              {helpLinkText}
            </Link>
          )}
          {termsOfUseUrl &&
            createFooterLinkFluent(
              AgreementType.TermsOfUse,
              termsOfUseUrl,
              touLinkText,
              footerLinkStyles,
            )}
          {privacyUrl &&
            createFooterLinkFluent(
              AgreementType.Privacy,
              privacyUrl,
              privacyLinkText,
              footerLinkStyles,
            )}
          {footerImpressumUrl &&
            createFooterLinkFluent(
              AgreementType.Impressum,
              footerImpressumUrl,
              impressumLinkText,
              footerLinkStyles,
            )}
          {footerA11yConformeUrl &&
            createFooterLinkFluent(
              AgreementType.A11yConforme,
              footerA11yConformeUrl,
              a11yConformanceLinkText,
              footerLinkStyles,
            )}
        </div>
        {!isHosted && (
          <div className={footerSectionStyles}>
            <FormattedTextWithBindingsFluent
              text={privateBrowsingLinkText}
              embeddedBindings={{
                learnMoreLink: createEmbeddedLinkFluent({ url: PrivateBrowsingLearnMoreUrl }),
              }}
              wrapperStyleName={privateBrowsingTextStyles}
            />
          </div>
        )}
      </footer>
    </div>
  );
};

export default FooterFluent;
