import React from "react";
import { type SpinnerProps, Spinner } from "@fluentui/react-components";
import StylesConfigFluent from "../../../config/styles-config-fluent";

export type ProgressIndicatorPropsFluent = Omit<SpinnerProps, "className, size, labelPosition">;

/**
 * ProgressIndicatorFullScreenFluent
 * This component uses the Fluent Spinner component when we want to display a large spinner
 * on a full screen
 * @param props the properties for the spinner
 * @returns A spinner component for Fluent
 */
export const ProgressIndicatorFullScreenFluent: React.FC<ProgressIndicatorPropsFluent> =
  function ProgressIndicatorFullScreenFluent(props) {
    const { centerHeightStyles } = StylesConfigFluent.instance.useCommonStyles();

    return <Spinner {...props} className={centerHeightStyles} size="large" labelPosition="below" />;
  };
