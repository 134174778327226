import { makeStyles, shorthands } from "@fluentui/react-components";

export const useHipChallengeStylesFluent = makeStyles({
  hipChallengeMenuStyles: {
    display: "inline-flex",
  },

  hipChallengeStyles: {
    paddingBottom: "30px",
  },

  hipContentStyle: {
    "& td": { textAlign: "center", paddingTop: "10px" },
    "& table": { display: "grid" },
    "& img": { textAlign: "center" },
    "& table tr": {
      display: "grid",
      marginBottom: "2px",
      "& input": {
        height: "36px",
        width: "140px",
        outlineStyle: "none",
        backgroundColor: "transparent",
        ...shorthands.borderStyle("solid"),
        ...shorthands.borderWidth(
          `1px !important`,
          `1px !important`,
          `1px !important`,
          `1px !important`,
        ),
        ...shorthands.borderRadius("4px", "4px", "4px", "4px"),
        ...shorthands.padding("0px", "12px !important", "0px", "12px !important"),
        ...shorthands.borderColor(`#c7c7c7`),
        ":focus-within": {
          ...shorthands.borderColor("", "", "rgb(0, 103, 184)", ""),
          ...shorthands.borderWidth("", "", "2px !important", ""),
          ...shorthands.borderRadius("4px", "4px", "0px", "0px"),
        },
      },
    },
    "& div [id*=wlspispContent]": {
      "& td": {
        display: "flex !important",
        justifyContent: "center",
        width: "100% !important",
      },
    },
  },
});

export type HipChallengeStylesDefaultFluent = ReturnType<typeof useHipChallengeStylesFluent>;
