import React from "react";
import { Route, Routes } from "react-router-dom";
import RouteAnimationFluent from "../../components/route-animation/fluent/route-animation-fluent";
import { AgreementViewRoute, FlowId, ViewId } from "../../constants";
import { getRouteFromViewId } from "../../utilities/routing-helper";
import { AgreementViewFluent } from "../../views";
import { type LoginFlowFluentProps } from "./model/login-flow-types-fluent";

/**
 * @param props The Login flow props needed returning connectors to each view
 * @returns the Login flow component
 */
export const LoginFlowFluent: React.FC<LoginFlowFluentProps> = function LoginFlowFluent(props) {
  const {
    InitialViewPickerViewFluent,
    PasswordEntryViewFluent,
    UsernameEntryViewFluent,
    PhoneDisambiguationViewFluent,
  } = props;

  return (
    <Routes>
      <Route path="*" element={<InitialViewPickerViewFluent />} />
      <Route path={getRouteFromViewId(ViewId.Password)} element={<PasswordEntryViewFluent />} />
      <Route path={getRouteFromViewId(ViewId.Username)} element={<UsernameEntryViewFluent />} />
      <Route
        path={getRouteFromViewId(ViewId.PhoneDisambiguation)}
        element={<PhoneDisambiguationViewFluent />}
      />
    </Routes>
  );
};

/**
 * @param props The Login flow props needed returning connectors to each view
 * @returns a wrapper component for the routes defined for the Login app.
 */
export const LoginRoutesFluent: React.FC<LoginFlowFluentProps> = function LoginRoutesFluent(props) {
  return (
    <RouteAnimationFluent>
      <Route path="*" element={<LoginFlowFluent {...props} />} />
      <Route
        path={AgreementViewRoute}
        element={<AgreementViewFluent hostingFlow={FlowId.Login} />}
      />
    </RouteAnimationFluent>
  );
};
