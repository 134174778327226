import React from "react";
import { Text } from "@fluentui/react-components";
import LinkFluent, { type LinkFluentProps } from "../../../components/link/fluent/link-fluent";

export const commonBindingsFluent = {
  boldText: (chunks: string[]) => <Text weight="semibold">{chunks}</Text>,
  strong: (chunks: string[]) => <strong>{chunks[0]}</strong>,
  break: () => <br />,
};

export type AnchorLinkPropsFluent = {
  url: string;
  onClick?: never;
  openInNewTab?: boolean;
  rel?: string;
};

export type SpanLinkPropsFluent = LinkFluentProps & {
  url?: never;
  onClick: (() => void) | ((event: React.SyntheticEvent<HTMLElement, Event>) => void);
  openInNewTab?: never;
  rel?: never;
};

export type EmbeddedLinkPropsFluent = AnchorLinkPropsFluent | SpanLinkPropsFluent;

/**
 * This method creates a dynamic React component for an anchor tag, to be used in the FormattedTextWithBindings component.
 * It expects the formatted text to have text content inside the link.
 * @param props EmbeddedLinkPropsFluent
 * @returns a React functional component that renders a link with the given url.
 */
export const createEmbeddedLinkFluent = function createEmbeddedLinkFluent(
  props: EmbeddedLinkPropsFluent,
) {
  const { url, onClick, openInNewTab = true, rel = "noreferrer noopener" } = props;

  return function EmbeddedLinkFluent(chunks: string[]) {
    const [text] = chunks;

    if (url) {
      return (
        <LinkFluent
          {...props}
          href={url}
          as="a"
          target={openInNewTab ? "_blank" : ""}
          rel={openInNewTab ? rel : undefined}
        >
          {text}
        </LinkFluent>
      );
    }

    return (
      <LinkFluent {...props} as="span" onClick={onClick}>
        {text}
      </LinkFluent>
    );
  };
};
