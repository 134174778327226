import React from "react";
import ReactDOM from "react-dom";
import { MemoryRouter } from "react-router-dom";
import { LoginFluent } from "@msidentity/sisu/apps/login/login-fluent";
import { Flavors } from "@msidentity/SISU/constants";
import { initLoginAppFluent } from "./model/init-login-app-fluent";

const serverData = window.ServerData;
const activeFlavor = Flavors.Fluent;

const loginAppFluentProps = initLoginAppFluent(serverData, activeFlavor);

ReactDOM.render(
  <React.StrictMode>
    <MemoryRouter>
      <LoginFluent {...loginAppFluentProps} />
    </MemoryRouter>
  </React.StrictMode>,
  document.getElementById("root"),
);
