import { makeStyles, shorthands, tokens } from "@fluentui/react-components";
import {
  lightboxBackgroundColor,
  lightboxMaxWidth,
  lightboxMaxWidthWide,
  lightboxPaddingL,
  lightboxPaddingS,
  screenSize,
  sectionPadding,
} from "../../../styles/fluent/constants-fluent.styles";

export const useLightboxLayoutStylesFluent = makeStyles({
  middle: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    ...shorthands.padding(lightboxPaddingL, tokens.spacingHorizontalNone, sectionPadding),

    [screenSize.medium]: {
      justifyContent: "flex-start",
      backgroundColor: lightboxBackgroundColor,
      ...shorthands.padding(0),
    },
  },

  inner: {
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
    marginRight: "auto",
    width: lightboxMaxWidth,
    alignItems: "center",
    backgroundColor: lightboxBackgroundColor,
    boxShadow: tokens.shadow16,
    ...shorthands.margin(tokens.spacingVerticalNone, "auto"),
    ...shorthands.padding(lightboxPaddingL),
    ...shorthands.borderRadius("12px"),

    "@media (forced-colors: active)": {
      ...shorthands.border("1px", "solid", "transparent"),
    },

    [screenSize.medium]: {
      width: "100%",
      boxShadow: "none",
      ...shorthands.borderRadius("0"),

      [screenSize.small]: {
        ...shorthands.padding(lightboxPaddingS),
      },
    },
  },

  innerWideStyle: {
    width: `calc(100% - ${lightboxPaddingL} * 2)`,
    maxWidth: lightboxMaxWidthWide,

    [screenSize.medium]: {
      width: "100%",
      maxWidth: "100%",
    },
  },
});

export type LighboxLayoutStylesDefaultFluent = ReturnType<typeof useLightboxLayoutStylesFluent>;
