import React from "react";
import { UsernameEntryViewRendererFluent } from "@msidentity/sisu/views/username-entry/fluent/username-entry-view-renderer-fluent";
import { useUsernameEntryViewFluent } from "./model/use-username-entry-view-fluent";

/**
 * Fluent Username entry view component
 * @returns A rendered instance of this component
 */
export const UsernameEntryViewConnectorFluent: React.FC =
  function UsernameEntryViewConnectorFluent() {
    return <UsernameEntryViewRendererFluent {...useUsernameEntryViewFluent()} />;
  };
