import React, { createContext, useContext, useMemo, useReducer } from "react";
import { type Theme, FluentProvider, webLightTheme } from "@fluentui/react-components";
import { HighContrastTheme } from "../../../constants";
import GlobalConfig from "../../../global-config";
import fluentThemeReducer, { type FluentThemeActions } from "./fluent-theme-reducer";

export type FluentThemeState = {
  theme: Theme;
  isDarkTheme: boolean;
  highContrastTheme: HighContrastTheme;
  isGaming: boolean;
};

export interface IFluentThemeContext {
  themeState: FluentThemeState;
  dispatchStateChange: React.Dispatch<FluentThemeActions>;
}

export const defaultFluentThemeState: FluentThemeState = {
  theme: webLightTheme,
  isDarkTheme: false,
  highContrastTheme: HighContrastTheme.none,
  isGaming: false,
};

export const FluentThemeContext = createContext<IFluentThemeContext>({
  themeState: defaultFluentThemeState,
  dispatchStateChange: () => {
    throw new Error("FluentThemeContext not initialized");
  },
});

export const useFluentThemeContext = () => useContext(FluentThemeContext);

/**
 * Create a theme context provider
 * @param props The input props for the provider
 * @param props.initialState The initial ThemeState that the provider should be initialized with
 * @param props.children The child components to render inside this provider
 * @returns The instantiated Provider component
 */
export const FluentThemeProvider: React.FC<{ initialState: FluentThemeState }> =
  function FluentThemeProvider({ initialState, children }) {
    const { isRtlMarket } = GlobalConfig.instance;
    const [state, dispatch] = useReducer(fluentThemeReducer, initialState);
    const value: IFluentThemeContext = useMemo(
      () => ({
        themeState: state,
        dispatchStateChange: dispatch,
      }),
      [state],
    );

    return (
      <FluentThemeContext.Provider value={value}>
        <FluentProvider theme={value.themeState.theme} dir={isRtlMarket ? "rtl" : "ltr"}>
          {children}
        </FluentProvider>
      </FluentThemeContext.Provider>
    );
  };
