import { type Flavors } from "../constants";
import { FlavorToStylesConfigMapFluent } from "../styles/flavor-to-styles-config-map-fluent";
import { StylesConfigDefaultFluent } from "../styles/fluent/styles-config-default-fluent";
import { ConfigWrapper } from "../utilities/config-wrapper";

export const defaultStylesConfigFluent = { ...StylesConfigDefaultFluent };

// eslint-disable-next-line deprecation/deprecation
const stylesConfigFluent = new ConfigWrapper(defaultStylesConfigFluent);

/**
 * The config wrapper that should be used to access the styles config properties
 */
export default stylesConfigFluent;

/**
 * Initialize the styles config object for flavors built with the fluent design language.
 * This function should be called once per App, outside of the component render cycle.
 * @param flavor The flavor of the active app
 */
export function initStylesConfigFluent(flavor: Flavors) {
  const mappedFlavorStylesFluent: Partial<typeof defaultStylesConfigFluent> =
    FlavorToStylesConfigMapFluent.get(flavor) ?? defaultStylesConfigFluent;

  stylesConfigFluent.initialize(mappedFlavorStylesFluent);
}
