import React from "react";
import StylesConfigFluent from "../../../config/styles-config-fluent";
import { type TitleContainerProps } from "../title-container-interface-fluent";
import { SubtitleFluent } from "./subtitle-fluent";
import { TitleFluent } from "./title-fluent";

/**
 * Title container component for Fluent
 * @param props TitleContainerProps
 * @returns a container component for the title and subtitle.
 */
export const TitleContainerFluent: React.FC<TitleContainerProps> = function TitleContainerFluent(
  props,
) {
  const { titleProps, subtitleProps, showSubtitle = true } = props;

  const { titleContainerStyles, subtitleWrapperStyles } =
    StylesConfigFluent.instance.useTitleStyles();

  const showSubtitleFlag = showSubtitle !== false && subtitleProps;

  return (
    <div className={titleContainerStyles}>
      <TitleFluent {...titleProps} />
      {showSubtitleFlag && (
        <div className={subtitleWrapperStyles}>
          <SubtitleFluent {...subtitleProps} />
        </div>
      )}
    </div>
  );
};
