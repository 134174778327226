import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

const codeInputWidthHeight = "40px";

export const useCodeEntryStylesFluent = makeStyles({
  // Wrapper used to apply custom focus styling to the code input
  codeInputWrapperStyles: {
    // focus is applied to the span that wraps the input box
    "&:focus-within span": {
      ...shorthands.borderColor(tokens.colorBrandStroke1),

      "::after": {
        // Removes the bottom border focus styling and animation from the input box
        content: "none",
      },
    },
  },

  codeInputStyles: {
    width: codeInputWidthHeight,
    height: codeInputWidthHeight,
    fontSize: tokens.fontSizeBase600,
    fontWeight: tokens.fontWeightSemibold,
    lineHeight: tokens.lineHeightBase400,
    paddingLeft: tokens.spacingHorizontalNone,
    paddingRight: tokens.spacingHorizontalNone,
    ...shorthands.borderColor(tokens.colorNeutralStroke1),

    "& input": {
      textAlign: "center",
    },
  },

  // Applies the layout and spacing for the code input boxes
  codeEntryWrapperStyles: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    ...shorthands.gap(tokens.spacingHorizontalS),
  },

  sixCodeEntryWrapperStyles: {
    // Extra space between the third and fourth input boxes
    "> :nth-child(4)": {
      marginLeft: tokens.spacingHorizontalS,
    },
  },

  // Wrapper used to align the field validation message with the input boxes
  fieldStyles: {
    justifyContent: "center",
  },
});

export type CodeEntryStylesDefaultFluent = ReturnType<typeof useCodeEntryStylesFluent>;
