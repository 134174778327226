import React from "react";
import { mergeClasses } from "@fluentui/react-components";
import StylesConfigFluent from "../../../config/styles-config-fluent";
import { type ViewButtonProps, ViewButtonFluent } from "./view-button-fluent";

/* The view button buttonType is determined by which props are passed into the view container
/* and shouldn't be manipulated */
export type ViewButtonContainerButtonProps = OmitWithoutExpanding<ViewButtonProps, "appearance">;

export type ViewButtonContainerProps = {
  primaryButton?: OmitWithoutExpanding<ViewButtonContainerButtonProps, "type">;
  secondaryButton?: ViewButtonContainerButtonProps;
  subtleButton?: ViewButtonContainerButtonProps;
  addMargin?: boolean;
};

/**
 * View Button Container component
 * @param props ViewContainerButtonsProps
 * @returns a container component for the buttons displayed in the lower half of a view consisting of a primary, secondary,
 * and subtle button, if props are passed for the target button. Otherwise that button is not rendered.
 */
export const ViewButtonContainerFluent: React.FC<ViewButtonContainerProps> =
  function ViewButtonContainerFluent(props) {
    const { primaryButton, secondaryButton, subtleButton, addMargin } = props;
    const { viewButtonContainerStyles } = StylesConfigFluent.instance.useButtonStyles();

    let containerStyles = viewButtonContainerStyles;

    if (addMargin) {
      const { sectionPaddingTopStyles } = StylesConfigFluent.instance.useCommonStyles();
      containerStyles = mergeClasses(containerStyles, sectionPaddingTopStyles);
    }

    return (
      <div className={containerStyles}>
        {primaryButton && (
          <ViewButtonFluent
            {...primaryButton}
            appearance="primary"
            type="submit"
            data-testid="primaryButton"
          />
        )}

        {secondaryButton && (
          <ViewButtonFluent
            {...secondaryButton}
            appearance="secondary"
            data-testid="secondaryButton"
          />
        )}

        {subtleButton && (
          <ViewButtonFluent {...subtleButton} appearance="subtle" data-testid="subtleButton" />
        )}
      </div>
    );
  };
