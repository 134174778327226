import { type ColorTokens } from "@fluentui/react-components";

export const defaultGamingDarkThemeColorOverrides: Partial<ColorTokens> = {
  // lightbox color
  colorNeutralBackground1: "#25282C",
  colorNeutralBackground3Hover: "#25282C",
  colorNeutralStrokeOnBrand: "#25282C",

  // default background color in place of background image
  // eventually this will be replaced with a gaming-specific image
  colorNeutralBackground3: "#1A1B1E",
};
