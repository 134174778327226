import React from "react";
import { InputFluent } from "../../../components/inputs/input/fluent/input-fluent";
import LinkFluent from "../../../components/link/fluent/link-fluent";
import { ViewContainerFluent } from "../../../components/view-container/fluent/view-container-fluent";
import StylesConfigFluent from "../../../config/styles-config-fluent";
import { type UsernameEntryViewRendererFluentProps } from "./model/username-entry-view-types-fluent";

/**
 * Fluent view renderer for username entry, example sign in.
 * @param props UsernameEntryViewRendererFluentProps
 * @param props.strings The required strings used in the view
 * @param props.handlers The link and button click handlers
 * @param props.inputProps The input properties
 * @param props.viewFooterProps The view footer properties
 * @returns A rendered instance of the Username entry view.
 */
export const UsernameEntryViewRendererFluent: React.FC<UsernameEntryViewRendererFluentProps> =
  function UsernameEntryViewRendererFluent(props: UsernameEntryViewRendererFluentProps) {
    const {
      strings: { title, subtitle, placeholder, forgotUsernameLinkLabel, primaryButtonLabel },
      handlers: { onSubmitHandler, forgotUsernameLinkHandler },
      inputProps,
      viewFooterProps,
    } = props;

    const { sectionPaddingTopStyles, linkPaddingTopStyles } =
      StylesConfigFluent.instance.useCommonStyles();

    return (
      <ViewContainerFluent
        titleContainerProps={{
          titleProps: { text: title },
          subtitleProps: { text: subtitle },
        }}
        viewFooterProps={viewFooterProps}
        buttonContainerProps={{
          primaryButton: {
            buttonText: primaryButtonLabel,
          },
        }}
        formProps={{ onSubmit: onSubmitHandler }}
      >
        <div className={sectionPaddingTopStyles}>
          <InputFluent
            inputProps={{ ...inputProps.inputProps, placeholder }}
            fieldProps={inputProps.fieldProps}
          />
          {forgotUsernameLinkHandler && (
            <div className={linkPaddingTopStyles}>
              <LinkFluent onClick={forgotUsernameLinkHandler}>{forgotUsernameLinkLabel}</LinkFluent>
            </div>
          )}
        </div>
      </ViewContainerFluent>
    );
  };
