import React from "react";
import { mergeClasses } from "@fluentui/react-components";
import StylesConfigFluent from "../../../config/styles-config-fluent";
import GlobalConfig from "../../../global-config";
import { useGlobalContext } from "../../../global-context";
import { GlobalActionType } from "../../../global-reducer";
import { useNavigateOrRedirectBack } from "../../../hooks";
import BannerLogoFluent from "../../banner-logo/fluent/banner-logo-fluent";
import { BannerButtonFluent } from "../../button/fluent/banner-button-fluent";

/**
 * BannerContainer component
 * @returns a component encapsulating the sub-components displayed above the ViewContainer.
 */
const BannerContainerFluent: React.FC = function BannerContainerFluent() {
  const { bannerContainerStyles } = StylesConfigFluent.instance.useBannerContainerStyles();
  const { sectionPaddingBottomStyles } = StylesConfigFluent.instance.useCommonStyles();
  const { isRtlMarket } = GlobalConfig.instance;
  const {
    dispatchStateChange: dispatchGlobalStateChange,
    globalState: {
      backButtonHandler,
      closeButtonHandler,
      showBackButtonOnActiveView,
      showCloseButtonOnActiveView,
    },
  } = useGlobalContext();

  const defaultBackHandler = useNavigateOrRedirectBack();

  const backArrowButtonClickHandler = () => {
    if (backButtonHandler) {
      backButtonHandler();
    } else {
      defaultBackHandler();
    }

    dispatchGlobalStateChange({
      type: GlobalActionType.SetShowProgressIndicator,
      payload: false,
    });
  };

  const onClick = showBackButtonOnActiveView ? backArrowButtonClickHandler : closeButtonHandler;

  const buttonProps = {
    isRtlMarket,
    showBackButtonOnActiveView,
    showCloseButtonOnActiveView,
    onClick,
  };

  return (
    <div className={mergeClasses(bannerContainerStyles, sectionPaddingBottomStyles)}>
      <BannerButtonFluent {...buttonProps} />
      <BannerLogoFluent />
    </div>
  );
};

export default BannerContainerFluent;
