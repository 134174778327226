import { useInputFluent } from "@msidentity/SISU/components/inputs/hooks/fluent/use-input-fluent";
import { type InputStateOptionsFluent } from "@msidentity/SISU/components/inputs/input/fluent/input-types-fluent";
import { type LoginInputValidationErrorStrings } from "@msidentity/SISU/flows/login/login-interface";
import { useServerDataErrorFluent } from "./use-server-data-error-fluent";

export type InputWithServerDataErrorsFluentProps = Omit<
  InputStateOptionsFluent,
  "initialServerError" | "clearServerErrorOnChange"
> & {
  loginInputValidationErrorStrings: LoginInputValidationErrorStrings;
};

/**
 * This hook is meant to be used to initialize the state required for an Input component.
 * If a ServerData error has been provided and hasn't been displayed, it will be used as the initial submit error.
 * If a submit error is present (whether from ServerData or from the input validation), it will be cleared when the input value changes.
 * @param options Options for the Input UX behavior
 * - `commonLoginStrings`: The common Login strings to use for the active Flavor.
 * - `hasInitialFocus`: An initial focus state for the Input. Defaults to false.
 * - `initialValue`: An initial value for the Input. Defaults to an empty string.
 * - `validationMethod`: A callback function to validate the input value.
 * @returns Input state and an `onChange` callback
 */
export const useInputWithServerDataErrorsFluent = (
  options: InputWithServerDataErrorsFluentProps,
) => {
  const { serverError } = useServerDataErrorFluent({
    strings: options.loginInputValidationErrorStrings,
  });

  return useInputFluent({
    ...options,
    initialServerError: serverError || "",
  });
};
