import { makeStyles } from "@fluentui/react-components";

export const usePhoneCountryDropdownStylesFluent = makeStyles({
  phoneCountrySelectStyles: {
    minWidth: "100%",
  },

  listboxStyles: {
    minWidth: "fit-content",
    maxHeight: "40vh",
  },
});

export default usePhoneCountryDropdownStylesFluent;

export type PhoneCountryDropdownStylesDefaultFluent = ReturnType<
  typeof usePhoneCountryDropdownStylesFluent
>;
