import { type ICountryInfo } from "../../../../../utilities/country-helper";
import { determineValidation, useInputFluent } from "../../../hooks/fluent/use-input-fluent";
import { type InnerFieldProps } from "../../../input/fluent/input-fluent";
import { type InputStateOptionsFluent } from "../../../input/fluent/input-types-fluent";
import {
  type PhoneNumberOptions,
  type PhoneNumberState,
  usePhoneNumber,
} from "../../hooks/use-phone-number";
import { type PhoneNumberFluentProps } from "../phone-types-fluent";

export type PhoneNumberOptionsFluent = Omit<PhoneNumberOptions, "inputState"> & {
  input: Pick<InputStateOptionsFluent, "initialValue">;
  countryData: ICountryInfo[];
};

export type PhoneNumberFluentState = PhoneNumberFluentProps & {
  phoneNumberState: PhoneNumberState;
  fieldProps: InnerFieldProps;
};

/**
 * This hook combines `useInputFluent` and `usePhoneNumber` to implement the state for the PhoneNumberFluent component
 * @param props The properties for this hook
 * @returns PhoneNumberFluentState, the properties to render the PhoneNumberFluent component
 */
export const usePhoneNumberFluent = function usePhoneNumberFluent(
  props: PhoneNumberOptionsFluent,
): PhoneNumberFluentState {
  const { countryData } = props;
  const { inputState, inputProps } = useInputFluent(props.input);
  const phoneNumberState = usePhoneNumber({
    ...props,
    inputState,
  });
  const fieldProps = determineValidation(phoneNumberState.error);

  return {
    dropdownProps: {
      countryData,
      onChange: phoneNumberState.onChangeDropdown,
      value: phoneNumberState.dropdownValue,
    },
    fieldProps,
    inputProps: {
      ...inputProps,
      onChange: phoneNumberState.onChangeInput,
      type: "tel",
    },
    phoneNumberState,
  };
};
