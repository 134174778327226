import React, { useRef } from "react";
import { type DropdownProps, Dropdown, Option } from "@fluentui/react-components";

export type DropdownOptionFluent = {
  /** The text for the option displayed in the list */
  optionText: string;
  /** The value associated with the option */
  value: string;
  /** The text displayed on the dropdown when the option is selected. It is also used as the comparison for type-to-find keyboard functionality. */
  displayText: string;
};

export type DropdownPropsFluent = DropdownProps & {
  wrapperStyleName?: string;
  dropdownStyleName?: string;
  listboxStyleName?: string;
  selectOptions: DropdownOptionFluent[];
  elementRef?: React.RefObject<HTMLButtonElement>;
  // Options for displaying a shortened version of the selected value text (e.g. +1) and optional style for customizing overflow text (e.g., by allowing long words to break and wrap).
  // This allows type-to-find keyboard functionality to match by displayText (e.g., United States (+1)) while displaying the shortened selected value text (e.g., +1) in the dropdown.
  truncatedSelectedValueTextOptions?: {
    selectedValueText: string;
    selectedValueTextStyleName?: string;
  };
};

/**
 * DropdownFluent
 * This component uses the Fluent Dropdown component, instead of the Select component.
 * The Fluent Dropdown component supports providing a display text for the dropdown
 * when the option is selected, in cases when that text differs from the option text
 * shown in the list (Phone country dropdown is an example use case for this)
 * @param props the properties for the dropdown
 * @returns A dropdown component for Fluent
 */
export const DropdownFluent: React.FC<DropdownPropsFluent> = function DropdownFluent(props) {
  const {
    wrapperStyleName,
    dropdownStyleName,
    listboxStyleName,
    selectOptions,
    elementRef,
    truncatedSelectedValueTextOptions,
  } = props;

  let ref = useRef<HTMLButtonElement>(null);
  if (elementRef) {
    ref = elementRef;
  }

  return (
    <div className={wrapperStyleName}>
      <Dropdown
        {...props}
        ref={ref}
        size="large"
        listbox={{ className: listboxStyleName }}
        className={dropdownStyleName}
        button={
          truncatedSelectedValueTextOptions && (
            <span
              className={truncatedSelectedValueTextOptions.selectedValueTextStyleName}
              data-testid="trunctedSelectedText"
            >
              {truncatedSelectedValueTextOptions.selectedValueText}
            </span>
          )
        }
      >
        {selectOptions.map((option) => (
          <Option text={option.displayText} key={option.value} value={option.value}>
            {option.optionText}
          </Option>
        ))}
      </Dropdown>
    </div>
  );
};
