import { ViewId } from "@msidentity/SISU/constants";
import { defaultActionFromPostedState } from "@msidentity/SISU/flows/login/initial-view-picker/fabric/initial-view-picker-fabric";
import { isInitialViewAccountPicker } from "@msidentity/SISU/flows/login/initial-view-picker/model/is-initial-view-account-picker";
import { Error, LoginMode } from "@msidentity/SISU/flows/login/login-constants";
import {
  getNextActionFromPostedState,
  isForceSignIn,
} from "@msidentity/SISU/flows/login/login-util";
import { type ILoginCredentials } from "@msidentity/SISU/model/credential";

/**
 * Logic to determine initial view for Fluent
 * @param loginMode the login mode from server data
 * @param credentials the credentials from server data
 * @param errorCode error code returned by server
 * @returns initial view ID
 */
export const determineInitialViewFluent = (
  loginMode: number,
  credentials: ILoginCredentials,
  errorCode?: string,
) => {
  const isForceSignin = isForceSignIn();

  // Default to either Account Picker or Username view
  const isNextViewAccountPicker = isInitialViewAccountPicker(
    loginMode,
    credentials.sessions?.length ?? 0,
    isForceSignin,
  );
  let nextViewId = isNextViewAccountPicker ? ViewId.AccountPicker : ViewId.Username;
  let actionFromPostedState = defaultActionFromPostedState;

  // This flag is to ensure that LoginMode has higher priority and certain views indicated
  // by LoginMode should be prioritized instead of trying to get next action from posted state
  let viewIdLocked = false;

  if (loginMode === LoginMode.Tiles) {
    // If the login mode was tiles, nextViewId will already be set to ViewId.AccountPicker
    viewIdLocked = true;
  }

  if (errorCode === Error.PP_E_PASSKEY_AUTH_INTERRUPTED) {
    nextViewId =
      credentials.availableCredentials?.length > 0 ? ViewId.CredentialPicker : ViewId.Username;
    viewIdLocked = true;
  }

  if (
    loginMode === LoginMode.UserCredentialPolicyBlocked ||
    loginMode === LoginMode.CredentialPicker
  ) {
    nextViewId = ViewId.CredentialPicker;
    viewIdLocked = true;
  }

  if (loginMode === LoginMode.Fido) {
    nextViewId = ViewId.Fido;
    viewIdLocked = true;
  }

  if (
    loginMode === LoginMode.GenericError ||
    loginMode === LoginMode.GenericErrorMobile ||
    loginMode === LoginMode.GenericErrorHost ||
    loginMode === LoginMode.SwitchUser ||
    loginMode === LoginMode.SwitchUserMobile ||
    loginMode === LoginMode.SwitchUserHost ||
    loginMode === LoginMode.InviteBlocked ||
    loginMode === LoginMode.ServiceBlocked ||
    loginMode === LoginMode.IDPFailed ||
    loginMode === LoginMode.HIP_Lockout ||
    loginMode === LoginMode.HIP_LockoutMobile ||
    loginMode === LoginMode.HIP_LockoutHost ||
    loginMode === LoginMode.BindFailed
  ) {
    nextViewId = ViewId.Error;
    viewIdLocked = true;
  }

  if (!viewIdLocked) {
    actionFromPostedState = getNextActionFromPostedState(nextViewId);
    const viewFromPostedState = actionFromPostedState.initialViewId;

    if (viewFromPostedState !== ViewId.None) {
      nextViewId = viewFromPostedState;
    }
  }

  return { nextViewId, actionFromPostedState };
};
