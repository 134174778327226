import React, { useContext } from "react";
import { Image } from "@fluentui/react-components";
import StylesConfigFluent from "../../../config/styles-config-fluent";
import { HighContrastTheme } from "../../../constants";
import { useFluentThemeContext } from "../../../context/theme/fluent/fluent-theme-context";
import GlobalConfig from "../../../global-config";
import { GlobalContext } from "../../../global-context";
import { ServiceDiagEventNames } from "../../../telemetry-helpers/service-diag-event-names";
import { useTelemetry } from "../../../telemetry-helpers/use-telemetry";
import { getBackgroundImageSource } from "../../../utilities/images-helper";

/**
 * BackgroundImageFluent component
 * @returns the background image
 */
export const BackgroundImageFluent: React.FC = function BackgroundImageFluent() {
  const { baseBackground } = StylesConfigFluent.instance.useBackgroundStyles();
  const {
    themeState: { highContrastTheme, isDarkTheme, isGaming },
  } = useFluentThemeContext();

  const { activeFlavor, telemetry } = GlobalConfig.instance;
  const {
    globalState: { activeView, activeFlow },
  } = useContext(GlobalContext);

  const { logServiceDiagEvent } = useTelemetry(telemetry, {
    activeView,
    activeFlow,
    activeFlavor,
  });

  // TODO: support custom background for gaming
  if (highContrastTheme !== HighContrastTheme.none || isGaming) {
    return null;
  }

  const sourceName = isDarkTheme ? "fluent_web_dark" : "fluent_web_light";
  let source;

  try {
    source = getBackgroundImageSource(sourceName, "jpg");
  } catch (e) {
    logServiceDiagEvent({
      metricName: ServiceDiagEventNames.ImageNotFound,
      metricValue: `background image for ${sourceName}`,
    });

    return null;
  }

  return <Image src={source} className={baseBackground} fit="cover" role="presentation" />;
};
