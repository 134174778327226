import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const useQrCodeStylesFluent = makeStyles({
  // The QR code image doesn't use the common imagePaddingStyles because it requires more padding. It doesn't have initial built-in padding unlike other images.
  qrCodeImagePaddingStyles: {
    ...shorthands.padding(tokens.spacingVerticalXXL, 0),
  },
});

export type QrCodeStylesDefaultFluent = ReturnType<typeof useQrCodeStylesFluent>;
