import { useArkoseEnforcementStylesFluent } from "../../components/arkose-enforcement/fluent/arkose-enforcement-fluent.styles";
import { useBackgroundStylesFluent } from "../../components/background/fluent/background-fluent.styles";
import { useBannerContainerStylesFluent } from "../../components/banner-container/fluent/banner-container-fluent.styles";
import { useButtonStylesFluent } from "../../components/button/fluent/button-fluent.styles";
import { useCodeEntryStylesFluent } from "../../components/code-entry/fluent/code-entry-fluent.styles";
import useCriticalErrorPageStylesFluent from "../../components/cricital-error-page/fluent/critical-error-page-fluent.styles";
import { useDebugDetailsStylesFluent } from "../../components/debug-details/fluent/debug-details-fluent.styles";
import { useDomainSelectStylesFluent } from "../../components/domain-select/fluent/domain-select-fluent.styles";
import { useEnvironmentBannerStylesFluent } from "../../components/environment-banner/fluent/environment-banner-fluent.styles";
import { useFooterStylesFluent } from "../../components/footer/fluent/footer-fluent.styles";
import { useHipChallengeStylesFluent } from "../../components/hip-challenge/fluent/hip-challenge-fluent.styles";
import { useIdentityBannerStylesFluent } from "../../components/identity-banner/fluent/identity-banner-fluent.styles";
import { useSelectDropdownStylesFluent } from "../../components/inputs/dropdown/fluent/select-dropdown-fluent.styles";
import { useInputStylesFluent } from "../../components/inputs/input/fluent/input-fluent.styles";
import { usePhoneCountryDropdownStylesFluent } from "../../components/inputs/phone-number/fluent/phone-country-dropdown-fluent.styles";
import { usePhoneNumberStylesFluent } from "../../components/inputs/phone-number/fluent/phone-number-fluent.styles";
import { useLightboxLayoutStylesFluent } from "../../components/layout/fluent/lightbox-layout-fluent.styles";
import { useQrCodeStylesFluent } from "../../components/qr-code/fluent/qr-code-fluent.styles";
import { useSuggestionsStylesFluent } from "../../components/suggestions/fluent/suggestions-fluent.styles";
import { useTileStylesFluent } from "../../components/tile/fluent/tile-fluent.styles";
import { useTitleStylesFluent } from "../../components/title/fluent/title-fluent.styles";
import { useViewContainerStylesFluent } from "../../components/view-container/fluent/view-container-fluent.styles";
import { type StylesConfigFluent } from "../types-fluent.styles";
import { useAgreementViewStylesFluent } from "./agreement-view-fluent.styles";
import { useAnimationStylesFluent } from "./animation-fluent.styles";
import useCommonStylesFluent, { useStaticCommonStylesFluent } from "./fluent.styles";

export const StylesConfigDefaultFluent: StylesConfigFluent = {
  useAgreementViewStyles: useAgreementViewStylesFluent,
  useAnimationStyles: useAnimationStylesFluent,
  useArkoseEnforcementStyles: useArkoseEnforcementStylesFluent,
  useBackgroundStyles: useBackgroundStylesFluent,
  useBannerContainerStyles: useBannerContainerStylesFluent,
  useButtonStyles: useButtonStylesFluent,
  useCodeEntryStyles: useCodeEntryStylesFluent,
  useCommonStyles: useCommonStylesFluent,
  useCriticalErrorPageStyles: useCriticalErrorPageStylesFluent,
  useDebugDetailsStyles: useDebugDetailsStylesFluent,
  useDomainSelectStyles: useDomainSelectStylesFluent,
  useEnvironmentBannerStyles: useEnvironmentBannerStylesFluent,
  useFooterStyles: useFooterStylesFluent,
  useHipChallengeStyles: useHipChallengeStylesFluent,
  useIdentityBannerStyles: useIdentityBannerStylesFluent,
  useInputStyles: useInputStylesFluent,
  useLightboxLayoutStyles: useLightboxLayoutStylesFluent,
  usePhoneCountryDropdownStyles: usePhoneCountryDropdownStylesFluent,
  usePhoneNumberStyles: usePhoneNumberStylesFluent,
  useQrCodeStyles: useQrCodeStylesFluent,
  useSelectDropdownStyles: useSelectDropdownStylesFluent,
  useSuggestionsStyles: useSuggestionsStylesFluent,
  useTileStyles: useTileStylesFluent,
  useTitleStyles: useTitleStylesFluent,
  useViewContainerStyles: useViewContainerStylesFluent,

  // Static styles
  useStaticCommonStyles: useStaticCommonStylesFluent,
};
