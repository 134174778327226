import { makeStyles } from "@fluentui/react-components";

export const oldHeightCSSProperty = "--old-height";
export const newHeightCSSProperty = "--new-height";

export const useAnimationStylesFluent = makeStyles({
  animateStyles: {
    transitionTimingFunction: "cubic-bezier(0.1, 0.9, 0.2, 1.0)",
    animationDuration: "0.25s",
    animationFillMode: "both",
  },

  routeAnimationContainerStyles: {
    width: "100%",
  },

  fadeInStyles: {
    // wait for height animation to complete before fading in
    animationDelay: "0.25s",
    // needed for firefox
    WebkitAnimationDelay: "0.25s",

    animationName: {
      "0%": {
        opacity: 0,
      },
    },
  },

  fadeOutStyles: {
    animationName: {
      "100%": {
        opacity: 0,
      },
    },
  },

  heightAnimationContainerStyles: {
    display: "flex",
  },

  // scale dynamically from previous height to new height
  dynamicHeightStyles: {
    animationName: {
      "0%": {
        height: "var(--old-height)",
      },
      "100%": {
        height: "var(--new-height)",
      },
    },
  },
});

export type AnimationStylesDefaultFluent = ReturnType<typeof useAnimationStylesFluent>;
