import React from "react";
import MicrosoftBannerLogoFluent from "./microsoft-banner-logo-fluent";

/**
 * BannerLogo component
 * TODO: Add support for branding logos, ESTS logo options (tenant logos, hide for Chind DC, etc.)
 * @returns a logo component for use in the banner, the Microsoft Logo as default.
 */
const BannerLogoFluent: React.FC = function BannerLogoFluent() {
  return <MicrosoftBannerLogoFluent />;
};

export default BannerLogoFluent;
