import { Flavors } from "../../../constants";
import {
  type DebugDetailsStringsFluent,
  DebugDetailsStringsConfigFluent,
  defaultDebugDetailsStringsFluent,
} from "./debug-details-strings-config-fluent";

/**
 * Maps a flavor (i.e Xbox, Fabric) to a set of flavored strings.
 * @param flavor The active flavor.
 */
const debugDetailsStringsFluentMap = new Map<Flavors, DebugDetailsStringsFluent>([
  [Flavors.Fluent, defaultDebugDetailsStringsFluent],
]);

/**
 * Initialize strings
 * @param flavor The active flavor.
 */
export function initDebugDetailsStringsFluent(flavor: Flavors) {
  DebugDetailsStringsConfigFluent.initialize({
    debugDetailsStrings: debugDetailsStringsFluentMap.get(flavor),
  });
}
