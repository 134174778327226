import React from "react";
import { mergeClasses } from "@fluentui/react-components";
import StylesConfigFluent from "../../../config/styles-config-fluent";
import {
  type FormattedTextWithBindingsProps,
  FormattedTextWithBindings,
} from "../formatted-text-with-bindings";

export type FormattedTextWithBindingsFluentProps = FormattedTextWithBindingsProps & {
  wrapperStyleName?: string;
};

/**
 * FormattedTextWithBindings component for use with Fluent UI
 * @param props FormattedTextWithBindingsPropsFluent
 * @returns a text component that can accept either a string or a formatted string with bindings.
 */
export const FormattedTextWithBindingsFluent: React.FC<FormattedTextWithBindingsFluentProps> =
  function FormattedTextWithBindingsFluent(props) {
    const { text, embeddedBindings, wrapperStyleName } = props;
    const { defaultTextColorStyles } = StylesConfigFluent.instance.useCommonStyles();
    const wrapperClassName = mergeClasses(defaultTextColorStyles, wrapperStyleName);

    return (
      <div className={wrapperClassName}>
        <FormattedTextWithBindings embeddedBindings={embeddedBindings} text={text} />
      </div>
    );
  };
