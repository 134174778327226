import React from "react";
import { MemoryRouter } from "react-router-dom";
import { AuthenticationProvider } from "../../authentication-context";
import { ChallengeProvider } from "../../challenge-reducer";
import { DfpIframe } from "../../components/device-finger-printing/dfp-iframe";
import ShellFluent from "../../components/shell/fluent/shell-fluent";
import { Flavors } from "../../constants";
import { BaseSisuProvidersFluent } from "../../context/sisu-providers/fluent/base-sisu-providers-fluent";
import { LoginProvider } from "../../flows/login/login-context";
import { LoginRoutesFluent } from "../../flows/login/login-flow-fluent";
import { initLoginAppFluent } from "./model/init-login-app-fluent";
import { type LoginAppFluentProps } from "./model/login-app-types-fluent";

const serverData = window.ServerData || {};
const activeFlavor = Flavors.Fluent;

const {
  initialProviderState,
  initialAuthenticationState,
  initialLoginState,
  initialChallengeState,
} = initLoginAppFluent(serverData, activeFlavor);

/**
 * The app encapsulating the Login flow. This is the outermost component
 * and will be rendered onto the page using ReactDOM.render.
 * @param props The fluent login app properties.
 * @returns An instance of the Login app.
 */
export const LoginFluent: React.FC<LoginAppFluentProps> = function LoginFluent(props) {
  const {
    initialGlobalState = initialProviderState.initialGlobalState,
    initialFluentThemeState = initialProviderState.initialFluentThemeState,
    initialHistoryState = initialProviderState.initialHistoryState,
    authenticationState = initialAuthenticationState,
    loginState = initialLoginState,
    challengeState = initialChallengeState,
    loginFlowViewProps,
    initialEntries,
  } = props;

  return (
    <MemoryRouter initialEntries={initialEntries}>
      <AuthenticationProvider initialState={authenticationState}>
        <BaseSisuProvidersFluent
          initialGlobalState={initialGlobalState}
          initialHistoryState={initialHistoryState}
          initialFluentThemeState={initialFluentThemeState}
        >
          <LoginProvider initialState={loginState}>
            <ChallengeProvider initialState={challengeState}>
              <ShellFluent>
                <LoginRoutesFluent {...loginFlowViewProps} />
                <DfpIframe />
              </ShellFluent>
            </ChallengeProvider>
          </LoginProvider>
        </BaseSisuProvidersFluent>
      </AuthenticationProvider>
    </MemoryRouter>
  );
};
