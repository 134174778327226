import { useSignupClickHandler } from "@msidentity/sisu/flows/login/hooks/login-hooks";
import { useRecoverUsernameClickHandler } from "@msidentity/sisu/flows/login/views/username/hooks/use-recover-username-click-handler";
import { createEmbeddedLinkFluent } from "@msidentity/sisu/utilities/formatted-text-with-bindings";

export type UsernameErrorLinkBindings = {
  signupLink: (chunks: string[]) => JSX.Element;
  recoverUsername: (chunks: string[]) => JSX.Element;
};

/**
 * This hook is used to get the signup and recover username link bindings, which is shown in the username error message.
 * @returns a function that provides embedded links for the username error message
 */
export const useUsernameErrorBindingsFluent = () => {
  const onSignupClicked = useSignupClickHandler();
  const onRecoverUsernameClicked = useRecoverUsernameClickHandler();

  const getEmbeddedLinkBindings = (username: string): UsernameErrorLinkBindings => {
    // Create the link button bindings for the formatted message
    const signupLink = createEmbeddedLinkFluent({ onClick: () => onSignupClicked(username) });
    const recoverUsername = createEmbeddedLinkFluent({
      onClick: () => onRecoverUsernameClicked(username),
    });

    return {
      signupLink,
      recoverUsername,
    };
  };

  return getEmbeddedLinkBindings;
};
