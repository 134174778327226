import { tokens } from "@fluentui/react-components";

export const defaultBackgroundColor = tokens.colorNeutralBackground3;
export const lightboxBackgroundColor = tokens.colorNeutralBackground1;

export const lightboxMaxWidth = "440px";
export const lightboxMaxWidthWide = "800px";

export const viewContainerMinWidth = "272px";

export const lightboxPaddingL = "40px";
export const lightboxPaddingS = tokens.spacingVerticalXXL;

export const defaultImagePadding = tokens.spacingVerticalM;
export const sectionPadding = tokens.spacingVerticalXXXL;
export const touchPadding = tokens.spacingVerticalM;

export const defaultTitleTextColor = tokens.colorNeutralForeground1;
export const defaultBodyTextColor = tokens.colorNeutralForeground2;

export const screenSize = {
  short: "@media (max-height: 640px)",
  medium: "@media (max-width: 519px)",
  small: "@media (max-width: 479px)",
};
