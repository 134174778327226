import React from "react";
import { Body1 } from "@fluentui/react-components";
import StylesConfigFluent from "../../../config/styles-config-fluent";
import { type TitleProps } from "../title-container-interface-fluent";

/**
 * Subtitle component
 * @param props TitleProps
 * @returns a text component styled as a subtitle.
 */
export const SubtitleFluent: React.FC<TitleProps> = function SubtitleFluent(props) {
  const { text } = props;

  const { defaultTextColorStyles } = StylesConfigFluent.instance.useCommonStyles();

  return (
    <Body1 align="center" className={defaultTextColorStyles} data-testid="subtitle">
      {text}
    </Body1>
  );
};
