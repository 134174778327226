import { type GriffelStyle, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { lightboxBackgroundColor } from "../../../../styles/fluent/constants-fluent.styles";
import { isIENewerThan10 as supportsMsReveal } from "../../../../utilities/browser-helper";

const floatedLabelStyles: GriffelStyle = {
  transform: "translateY(-100%)",
  transitionProperty: "all",
  transitionDuration: tokens.durationNormal,
  transitionTimingFunction: tokens.curveDecelerateMid,

  position: "absolute",
  fontSize: "10px",
  lineHeight: "14px",
  left: tokens.spacingHorizontalS,
  right: tokens.spacingHorizontalS,
  // top is half of the line height to center label
  top: "7px",
};

export const useInputStylesFluent = makeStyles({
  inputStyles: {
    // override column gap styles set between contentAfter, input, and contentBefore
    columnGap: 0,
    lineHeight: tokens.lineHeightBase400,
    fontSize: tokens.fontSizeBase300,
    ...shorthands.padding(0, tokens.spacingHorizontalS),
    minWidth: 0,
  },

  // Hides the up/down arrows (spin button) on number inputs (e.g., birthdate year input).
  hideSpinButton: {
    "& input[type='number']": {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        ...shorthands.margin(0),
      },
      // Firefox
      "-moz-appearance": "textfield",
    },
  },

  floatingLabelContainerStyles: {
    width: "100%",
    position: "relative",

    "&:focus-within label": {
      ...floatedLabelStyles,
    },

    "& input": { flexBasis: 0, flexShrink: 99 },
  },

  // normal sized placeholder
  floatingPlaceholderStyles: {
    color: tokens.colorNeutralForeground4,
    backgroundColor: lightboxBackgroundColor,
    cursor: "text",
    // needed to make sure contentBefore is wide enough for the placeholder
    width: "max-content",
    position: "relative",
    ...shorthands.padding(0, tokens.spacingHorizontalXS),
  },

  disabledFloatingPlaceholderStyles: {
    color: tokens.colorNeutralForegroundDisabled,
    cursor: "not-allowed",
  },

  floatingLabelStyles: {
    ...floatedLabelStyles,
  },

  passwordInputStyles: {
    // This hides the password-reveal button by default
    "& button": {
      display: "none",
    },
    // This reveals the password-reveal button when the input is hovered or focused on
    "&:hover button, &:focus-within button": {
      display: "inline-flex",
    },
    // This hides the Edge native password-reveal button so we can only show our own
    ...(supportsMsReveal()
      ? {
          "& ::-ms-reveal": {
            display: "none",
          },
        }
      : {}),
  },

  // This adds extra space above the floating label so that it doesn't overlap with the component above it
  floatingLabelContainerTopPadding: {
    marginTop: tokens.spacingVerticalS,
  },

  // This adds extra space above inputs that are stacked vertically below another input
  stackedInputContainerStyles: {
    marginTop: tokens.spacingVerticalXXL,
  },
});

export type InputStylesDefaultFluent = ReturnType<typeof useInputStylesFluent>;
