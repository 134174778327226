import { ConfigWrapper } from "../../../utilities/config-wrapper";

export const defaultFooterStringsFluent = {
  helpLinkText: getLocalString("General_Help"),
  touLinkText: getLocalString("General_TermsOfUse"),
  privacyLinkText: getLocalString("Privacy_And_Cookies"),
  impressumLinkText: getLocalString("Impressum_Link"),
  a11yConformanceLinkText: getLocalString("A11y_Conformance_Footer_Url_Text"),
  privateBrowsingLinkText: getLocalString("Private_Browsing_Information_Footer_Text"),
};

export type FooterStringsFluent = typeof defaultFooterStringsFluent;

// eslint-disable-next-line deprecation/deprecation
export const FooterStringsConfigFluent = new ConfigWrapper({
  footerStrings: defaultFooterStringsFluent,
});
