import { makeStyles, tokens } from "@fluentui/react-components";

export const useTileStylesFluent = makeStyles({
  tileListStyles: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    rowGap: tokens.spacingVerticalM,
  },

  tileStyles: {
    width: "100%",
    display: "flex",
  },

  cardHeaderStyles: {
    gridTemplateRows: "1fr",
  },

  iconRightStyles: {
    display: "flex",
    alignItems: "center",
  },
});

export type TileStylesDefaultFluent = ReturnType<typeof useTileStylesFluent>;
