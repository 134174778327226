import { useFormSubmission } from "@msidentity/SISU/components/inputs/hooks/use-form-submission";
import { useUsernameInputValidation } from "@msidentity/SISU/components/inputs/input/username/use-username-input-validation";
import { FlowId, ViewId } from "@msidentity/SISU/constants";
import { useSignupClickHandler } from "@msidentity/SISU/flows/login/hooks/login-hooks";
import LoginConfig from "@msidentity/sisu/flows/login/login-config";
import { useGct } from "@msidentity/SISU/flows/login/views/username/hooks";
import { useActivateViewWithDocumentTitle, useRedirect } from "@msidentity/SISU/hooks";
import { createEmbeddedLinkFluent } from "@msidentity/sisu/utilities/formatted-text-with-bindings";
import { type UsernameEntryViewRendererFluentProps } from "@msidentity/SISU/views/username-entry/fluent/model/username-entry-view-types-fluent";
import { loginInputValidationErrorStringsFluent } from "../../../model/login-input-validation-error-strings-fluent";
import { useInputWithServerDataErrorsFluent } from "../../../model/use-input-with-server-data-errors-fluent";
import { useGetErrorMessageForGctFluent } from "./use-get-error-message-for-gct-fluent";
import { usernameEntryStringsFluent } from "./username-entry-view-strings-fluent";

/**
 * Aggregator hook for fluent username entry.
 * @returns Props needed by the renderer of type UsernameEntryViewRendererFluentProps.
 */
export const useUsernameEntryViewFluent =
  function useUsernameEntryViewFluent(): UsernameEntryViewRendererFluentProps {
    const activeViewId = ViewId.Username;
    const {
      title,
      subtitle,
      placeholder,
      forgotUsernameLinkLabel,
      primaryButtonLabel,
      viewFooterLinkLabel,
      errorStringInvalidMemberName,
      errorStringInvalidPhoneNumber,
    } = usernameEntryStringsFluent;
    const { allowPhone, allowSkype, forgotUsernameUrl } = LoginConfig.instance;

    useActivateViewWithDocumentTitle(title, activeViewId, FlowId.Login, {
      showIdentityBanner: false,
    });

    // Input set up
    const validationMethod = useUsernameInputValidation({
      allowSkype,
      allowPhone,
      errorStringInvalidMemberName,
      errorStringInvalidPhoneNumber,
    });

    const inputProps = useInputWithServerDataErrorsFluent({
      initialValue: "", // TODO Get username from useUsernameViewPropertiesFabric
      validationMethod,
      loginInputValidationErrorStrings: loginInputValidationErrorStringsFluent,
    });
    const { inputState } = inputProps;

    const getErrorMessage = useGetErrorMessageForGctFluent();

    const onSubmitHandler = useFormSubmission({
      inputState,
      viewId: activeViewId,
      submitTask: useGct({
        viewId: activeViewId,
        getErrorMessage,
      }),
    });

    const onSignupClicked = useSignupClickHandler();
    const viewFooterLinkHandler = () => {
      onSignupClicked(String(inputState.value));
    };

    const embeddedViewFooterLink = createEmbeddedLinkFluent({ onClick: viewFooterLinkHandler });

    // Forgot username set up
    const forgotUsernameOnClick = useRedirect();

    return {
      strings: {
        title,
        subtitle,
        placeholder,
        forgotUsernameLinkLabel,
        primaryButtonLabel,
        viewFooterLinkLabel,
      },
      handlers: {
        onSubmitHandler,
        forgotUsernameLinkHandler: () => {
          forgotUsernameOnClick(forgotUsernameUrl, true);
        },
      },
      inputProps,
      viewFooterProps: {
        text: usernameEntryStringsFluent.viewFooterLinkLabel,
        bindings: { signUpLink: embeddedViewFooterLink },
      },
    };
  };
