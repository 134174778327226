import { makeStyles, shorthands, tokens } from "@fluentui/react-components";
import {
  lightboxPaddingL,
  lightboxPaddingS,
  screenSize,
} from "../../../styles/fluent/constants-fluent.styles";

// TODO: Replace colorNeutralBackgroundAlpha with custom
// token that uses the same colors as colorNeutralBackground3
// but with .5 alpha.
export const useFooterStylesFluent = makeStyles({
  footerStyles: {
    left: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    zIndex: "2",
    minHeight: tokens.spacingVerticalXXL,
    lineHeight: tokens.lineHeightBase400,
    ...shorthands.margin(tokens.spacingVerticalXXL, 0),
    ...shorthands.padding(0, tokens.spacingHorizontalXXL),
    rowGap: tokens.spacingVerticalM,

    [screenSize.short]: {
      backgroundColor: tokens.colorNeutralBackgroundAlpha,
    },

    [screenSize.medium]: {
      backgroundColor: "inherit",
      ...shorthands.padding(0, lightboxPaddingL),
      ...shorthands.margin(tokens.spacingVerticalXXL, "auto"),

      [screenSize.small]: {
        ...shorthands.padding(0, lightboxPaddingS),
      },
    },
  },

  footerLinkStyles: {
    position: "relative",
    fontSize: tokens.fontSizeBase200,
    lineHeight: tokens.lineHeightBase400,
    height: tokens.fontSizeBase200, // make link buttons the same height as the link text

    // Make both footer links and footer buttons look like links in high contrast mode
    "@media (forced-colors: active)": {
      color: "LinkText",

      ":hover": { color: "LinkText" },
    },
  },

  footerSectionStyles: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    flexShrink: 0,
    width: "100%",
    ...shorthands.gap(tokens.spacingHorizontalXXL, tokens.spacingVerticalM),
  },

  privateBrowsingTextStyles: {
    fontSize: tokens.fontSizeBase100,
  },

  footerContainerStyles: {
    display: "flex",
  },
});

export type FooterStylesDefaultFluent = ReturnType<typeof useFooterStylesFluent>;
