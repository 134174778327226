import React from "react";
import { Badge } from "@fluentui/react-components";
import StylesConfigFluent from "../../../config/styles-config-fluent";

export type IdentityBannerProps = {
  userDisplayName: string;
};

/**
 * IdentityBanner component
 * @param props IdentityBannerProps
 * @returns an instance of the identity banner badge diplayed below the logo banner in a view.
 */
const IdentityBannerFluent: React.FC<IdentityBannerProps> = function IdentityBannerFluent(props) {
  const { userDisplayName } = props;
  const { idBannerStyles } = StylesConfigFluent.instance.useIdentityBannerStyles();

  // TODO: Show a tooltip if the userDisplayName is too long
  return (
    <div className={idBannerStyles}>
      <Badge size="extra-large" color="important" appearance="outline" data-testid="identityBanner">
        {userDisplayName}
      </Badge>
    </div>
  );
};

export default IdentityBannerFluent;
