import { makeStyles, tokens } from "@fluentui/react-components";
import { defaultTitleTextColor } from "../../../styles/fluent/constants-fluent.styles";

export const useTitleStylesFluent = makeStyles({
  subtitleWrapperStyles: {
    paddingTop: tokens.spacingVerticalS,
  },

  titleContainerStyles: {
    textAlign: "center",
    justifyContent: "center",
    position: "relative",
  },

  titleStyles: {
    color: defaultTitleTextColor,
  },
});

export type TitleStylesDefaultFluent = ReturnType<typeof useTitleStylesFluent>;
