import { type UsernameEntryViewRendererStringsFluent } from "@msidentity/sisu/views/username-entry/fluent/model/username-entry-view-types-fluent";

export type UsernameEntryViewStringsFluent = UsernameEntryViewRendererStringsFluent & {
  errorStringInvalidMemberName: string;
  errorStringInvalidPhoneNumber: string;
};

export const usernameEntryStringsFluent: UsernameEntryViewStringsFluent = {
  title: getLocalString("SignIn_Title"),
  subtitle: getLocalString("SignIn_Subtitle"),
  placeholder: getLocalString("EmailOrPhoneNumber_Placeholder"),
  forgotUsernameLinkLabel: getLocalString("ForgotYourUsernameLink"),
  primaryButtonLabel: getLocalString("General_Buttons_Next"),
  viewFooterLinkLabel: getLocalString("SignIn_CreateAccountLink"),
  errorStringInvalidMemberName: getLocalString("Error_InvalidMemberName"),
  errorStringInvalidPhoneNumber: getLocalString("Error_1208"),
};
