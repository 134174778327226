import React from "react";
import { type SpinnerProps, Spinner } from "@fluentui/react-components";

export type ProgressIndicatorPropsFluent = Omit<SpinnerProps, "size, labelPosition">;
/**
 * ProgressIndicatorOnScreenFluent
 * This component uses the Fluent Spinner component when we want to display it on screen
 * along with other parts of the view
 * @param props the properties for the spinner
 * @returns A spinner component for Fluent
 */
export const ProgressIndicatorOnScreenFluent: React.FC<ProgressIndicatorPropsFluent> =
  function ProgressIndicatorOnScreenFluent(props) {
    return <Spinner {...props} size="tiny" labelPosition="after" />;
  };
