import { type FluentThemeState } from "./fluent-theme-context";

export enum FluentThemeActionType {
  SetThemeAction,
}

type SetFluentThemeAction = {
  type: FluentThemeActionType.SetThemeAction;
  payload: FluentThemeState;
};

export type FluentThemeActions = SetFluentThemeAction;

/**
 * Theme state reducer
 * @param state The current state
 * @param action The action to perform on the current state
 * @returns The new state
 */
export default function fluentThemeReducer(
  state: FluentThemeState,
  action: FluentThemeActions,
): FluentThemeState {
  switch (action.type) {
    case FluentThemeActionType.SetThemeAction:
      return {
        ...state,
        theme: action.payload.theme,
        isDarkTheme: action.payload.isDarkTheme,
        highContrastTheme: action.payload.highContrastTheme,
        isGaming: action.payload.isGaming,
      };

    default:
      throw new Error(`ThemeReducer received unexpected action ${action.type}`);
  }
}
