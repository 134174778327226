import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { mergeClasses } from "@fluentui/react-components";
import StylesConfigFluent from "../../../config/styles-config-fluent";
import { EnvironmentName } from "../../../constants/environmentbanner-constants";
import GlobalConfig from "../../../global-config";
import { useGlobalContext } from "../../../global-context";
import { setDefaultFocusFluent } from "../../../hooks/fluent/use-set-default-focus-fluent";
import { BackgroundImageFluent } from "../../background/fluent/background-image-fluent";
import EnvironmentBannerFluent from "../../environment-banner/fluent/environment-banner-fluent";
import FooterFluent from "../../footer/fluent/footer-fluent";
import LayoutContentFluent from "./layout-content-fluent";

/**
 * LightboxLayout component
 * @param props Props for lightbox layout
 * @param props.children The child elements to render inside this component
 * @returns The lightbox wrapper for the flow
 */
const LightboxLayoutFluent: React.FC = function LightboxLayoutFluent({ children }) {
  const { bannerEnvironment } = GlobalConfig.instance;
  const {
    globalState: { isWideView, setDefaultFocusInFooter },
  } = useGlobalContext();

  const shouldShowBanner = bannerEnvironment !== EnvironmentName.None && bannerEnvironment;

  const { useLightboxLayoutStyles, useStaticCommonStyles, useCommonStyles } =
    StylesConfigFluent.instance;

  useStaticCommonStyles();
  const { inner, innerWideStyle, middle } = useLightboxLayoutStyles();
  const { outer } = useCommonStyles();
  const [showDebugDetails, setShowDebugDetails] = React.useState(false);

  const innerStyleName = isWideView ? mergeClasses(inner, innerWideStyle) : inner;

  // close debug details on route change
  const location = useLocation();
  useEffect(() => {
    setShowDebugDetails(false);
  }, [location]);

  const helpLinkRef = React.useRef<HTMLButtonElement>(null);
  const footerContainerRef = React.useRef(null);

  // This sets the default focus within the footer when the view enables the option setDefaultFocusInFooter
  // It should be used when there is no focusable element in the view, so we default to the footer instead
  useEffect(() => {
    if (!setDefaultFocusInFooter) {
      return () => {};
    }

    return setDefaultFocusFluent(footerContainerRef);
  }, [footerContainerRef, setDefaultFocusInFooter]);

  return (
    <div data-testid="lightbox-layout">
      <div id="outer" data-testid="outer" className={outer} role="presentation">
        <BackgroundImageFluent />
        {shouldShowBanner && <EnvironmentBannerFluent />}
        <div id="middle" className={middle} role="main">
          <LayoutContentFluent
            innerClassName={innerStyleName}
            showDebugDetails={showDebugDetails}
            onDebugDetailsCloseClick={() => {
              setShowDebugDetails(false);
              helpLinkRef.current?.focus();
            }}
          >
            {children}
          </LayoutContentFluent>
        </div>
        <FooterFluent
          isDebugDetailsExpanded={showDebugDetails}
          onHelpLinkClick={() => setShowDebugDetails(!showDebugDetails)}
          helpLinkRef={helpLinkRef}
          footerContainerRef={footerContainerRef}
        />
      </div>
    </div>
  );
};

export default LightboxLayoutFluent;
