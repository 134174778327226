import React from "react";
import { type GlobalState, GlobalProvider } from "../../../global-context";
import { type HistoryState, HistoryProvider } from "../../../history-context";
import {
  type FluentThemeState,
  defaultFluentThemeState,
  FluentThemeProvider,
} from "../../theme/fluent/fluent-theme-context";

export type BaseSisuStateFluent = {
  initialGlobalState: GlobalState;
  initialHistoryState: HistoryState;
  initialFluentThemeState?: FluentThemeState;
};

/**
 * @param props BaseSisuStateFluent - the initial states for the common providers
 * @param props.initialGlobalState GlobalState
 * @param props.initialHistoryState HistoryState
 * @param props.initialFluentThemeState FluentThemeState
 * @param props.children children
 * @returns a shared provider component used by all fabric-based apps.
 */
export const BaseSisuProvidersFluent: React.FC<BaseSisuStateFluent> =
  function BaseSisuProvidersFluent({
    initialGlobalState,
    initialHistoryState,
    initialFluentThemeState = defaultFluentThemeState,
    children,
  }) {
    return (
      <FluentThemeProvider initialState={initialFluentThemeState}>
        <GlobalProvider initialState={initialGlobalState}>
          <HistoryProvider initialState={initialHistoryState}>{children}</HistoryProvider>
        </GlobalProvider>
      </FluentThemeProvider>
    );
  };
