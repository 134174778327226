import React from "react";
import { PasswordFluent } from "../../../components/inputs/password/fluent/password-fluent";
import LinkFluent from "../../../components/link/fluent/link-fluent";
import { ViewContainerFluent } from "../../../components/view-container/fluent/view-container-fluent";
import StylesConfigFluent from "../../../config/styles-config-fluent";
import { LoginFlowPostHiddenInputs } from "../../../flows/login/login-flow-post-hidden-inputs";
import { type PasswordEntryViewRendererFluentProps } from "./model/password-entry-view-types-fluent";

/**
 * Fluent view renderer for password entry, example sign in.
 * @param props PasswordEntryViewRendererFluentProps
 * @param props.strings The required strings used in the view
 * @param props.handlers The handlers for links and buttons on the view
 * @param props.passwordProps The password input properties
 * @param props.loginPostProps (Optional) The props for the Login post hidden inputs
 * @param props.formProps (Optional) Form properties for the view container form
 * @returns A rendered instance of the Password entry view.
 */
export const PasswordEntryViewRendererFluent: React.FC<PasswordEntryViewRendererFluentProps> =
  function PasswordEntryViewRendererFluent(props: PasswordEntryViewRendererFluentProps) {
    const {
      strings: { title, forgotPasswordLinkLabel, primaryButtonLabel },
      handlers: { forgotPasswordLinkHandler, onSubmitHandler },
      passwordProps,
      loginPostProps,
      formProps,
    } = props;

    const { sectionPaddingTopStyles, linkPaddingTopStyles } =
      StylesConfigFluent.instance.useCommonStyles();

    return (
      <ViewContainerFluent
        titleContainerProps={{
          titleProps: { text: title },
        }}
        buttonContainerProps={{
          primaryButton: { buttonText: primaryButtonLabel },
        }}
        formProps={{ ...formProps, onSubmit: onSubmitHandler }}
        // TODO: add additional links, i.e. credential switch link, phone disambiguation link, change user link
      >
        {loginPostProps && <LoginFlowPostHiddenInputs {...loginPostProps} />}

        <div className={sectionPaddingTopStyles}>
          <PasswordFluent {...passwordProps} />

          {forgotPasswordLinkHandler && forgotPasswordLinkLabel && (
            <div className={linkPaddingTopStyles}>
              <LinkFluent onClick={forgotPasswordLinkHandler}>{forgotPasswordLinkLabel}</LinkFluent>
            </div>
          )}
        </div>
      </ViewContainerFluent>
    );
  };
