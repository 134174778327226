import { type LoginInputValidationErrorStrings } from "@msidentity/SISU/flows/login/login-interface";

export const loginInputValidationErrorStringsFluent: LoginInputValidationErrorStrings = {
  accountPasswordIncorrectError: getLocalString("Login_AccountPasswordIncorrect_Error"),
  aliasAuthCollisionError: getLocalString("Login_AliasAuthCollision_Error"),
  aliasDisabledError: getLocalString("Login_AliasDisabled_Error"),
  bindingExistsSamsungError: getLocalString("BindFailed_AlreadyBound_Desc_Samsung"),
  fedNotAllowedError: getLocalString("Login_FederationNotAllowed_Error"),
  fedNotAllowedErrorWithPartner: getLocalString("Login_Fed_LoginNotAllowed_Error"),
  fidoPasskeyError: getLocalString("Login_Fido_Passkey_Error"),
  githubFedUserNotFoundError: getLocalString("Login_FedUserNotFoundError_GitHub"),
  googleFedUserNotFoundError: getLocalString("Login_FedUserNotFoundError_Google"),
  incorrectOneTimeCodeError: getLocalString("IncorrectOTC_Error"),
  invalidEmailError: getLocalString("Login_AltEmailInvalid_Error"),
  invalidMemberName: getLocalString("SignIn_Error_EmailMissing"),
  linkedInFedUserNotFoundError: getLocalString("Login_FedUserNotFoundError_LinkedIn"),
  memberDoesNotExistError: getLocalString("Login_MembernameNotExists_Error"),
  missingPasswordError: getLocalString("Login_MissingPassword_Error"),
  ngcAuthFailedError: getLocalString("Login_FIDO_AuthFailed_Error"),
  notOverSslError: getLocalString("NotOverSSL_Error"),
  oldSkypePasswordError: getLocalString("Login_Password_OldSkypePasswordError"),
  ottInvalidPurposeError: getLocalString("OTC_WrongPurpose_Error"),
  realmNotFederatedError: getLocalString("Login_Fed_RealmNotFederated_Error"),
  reservedUsernameError: getLocalString("Login_EmailPasswordIncorrect_Error"),
  sessionExpiredError: getLocalString("SessionExpired_Error"),
  tooManyRequestsError: getLocalString("Login_TooManyRequests_Error"),
  uniqueIdExistsSamsungError: getLocalString("BindFailed_UniqueIdExists_Desc_Samsung"),
  wrongPasswordError: getLocalString("Login_IncorrectPassword_Error"),
  wrongPasswordLockedUserError: getLocalString("Login_WrongPasswordError_LockedUser"),
};
