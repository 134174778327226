import { makeStyles } from "@fluentui/react-components";
import { lightboxMaxWidth, screenSize } from "../../../styles/fluent/constants-fluent.styles";

export const useBannerContainerStylesFluent = makeStyles({
  bannerContainerStyles: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "1fr auto 1fr",

    [screenSize.medium]: {
      maxWidth: lightboxMaxWidth,
    },
  },
});

export type BannerContainerStylesDefaultFluent = ReturnType<typeof useBannerContainerStylesFluent>;
