import React from "react";
import { PhoneDisambiguationViewRendererFluent } from "@msidentity/SISU/views/phone-disambiguation";
import { phoneDisambiguationViewStringsFluent } from "./model/phone-disambiguation-view-strings-fluent";
import { usePhoneDisambiguationViewFluent } from "./model/use-phone-disambiguation-view-fluent";

/**
 * Fluent Phone Disambiguation view connector.
 * @returns The Phone Disambiguation view renderer using aggregated props
 */
export const PhoneDisambiguationViewConnectorFluent: React.FC =
  function PhoneDisambiguationViewConnectorFluent() {
    const props = usePhoneDisambiguationViewFluent(phoneDisambiguationViewStringsFluent);
    return <PhoneDisambiguationViewRendererFluent {...props} />;
  };
