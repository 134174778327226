import { makeStyles } from "@fluentui/react-components";
import { screenSize } from "../../../styles/fluent/constants-fluent.styles";

export const useBackgroundStylesFluent = makeStyles({
  baseBackground: {
    position: "fixed",

    // DO NOT REPEAT THIS PATTERN
    // This is a CSS hack we are using to make the rendering of the background more deterministic in Chrome for use in Integration Testing.
    // We should NOT have CSS that is only applied in Storybook like this, but Chrome is not rendering the background image deterministically.
    // Chrome is rendering the image just different enough that the snapshot tests are failing, but are impossible to see with the human eye.
    // We are not including this CSS for Production because the variance is impossible to detect and shouldn't be needed in the first place.
    // If you ever think you need to add something similarly, please reach out to the team before doing such.
    ...(IS_STORYBOOK
      ? {
          "-webkit-transform": "translateZ(0)",
        }
      : {}),

    [screenSize.medium]: { display: "none" },
  },
});

export type BackgroundStylesDefaultFluent = ReturnType<typeof useBackgroundStylesFluent>;
