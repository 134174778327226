import { defaultFocusTimeoutMs } from "../../constants";
import { useGlobalContext } from "../../global-context";
import { getKeyboardFocusableElements } from "../../utilities/browser-helper";
import { useEffectOnce } from "../use-effect-once";

/**
 * Focuses the first focusable element in the containerElementRef that is not in the excluded list
 * @param containerElementRef we will search for a focusable element within this container
 * @param excludedIds a list of element IDs that should not be focused unless there are no  other options
 * @returns a function that clears the focus timeout, or a void function if there is no element to focus
 */
export const setDefaultFocusFluent = (
  containerElementRef: React.RefObject<HTMLElement>,
  excludedIds: string[] = [],
) => {
  if (!containerElementRef.current) {
    return () => {};
  }

  const keyboardFocusableElements = getKeyboardFocusableElements(containerElementRef.current);

  if (keyboardFocusableElements.length === 0) {
    return () => {};
  }

  const elementToFocus =
    keyboardFocusableElements.find((element) => !excludedIds.includes(element.id)) ??
    keyboardFocusableElements[0];

  const focusTimeout = setTimeout(() => {
    elementToFocus?.focus();
  }, defaultFocusTimeoutMs);

  return () => clearTimeout(focusTimeout);
};

/**
 * BE VERY CAREFUL ABOUT USING THIS HOOK
 * Fluent view container handles setting default focus on initial render (usually from route changes),
 * so you should only use this hook if you are sure that it will not conflict with the view container behavior.
 *
 * Focuses the first focusable element in the containerElementRef that is not in the excluded list
 * @param containerElementRef we will search for a focusable element within this container
 * @param excludedIds a list of element IDs that should not be focused unless there are no  other options
 */
export const useSetDefaultFocusFluent = (
  containerElementRef: React.RefObject<HTMLElement>,
  excludedIds: string[] = [],
) => {
  const {
    globalState: { setDefaultFocusInFooter },
  } = useGlobalContext();

  useEffectOnce(() => {
    if (setDefaultFocusInFooter) {
      return () => {};
    }

    return setDefaultFocusFluent(containerElementRef, excludedIds);
  });
};
