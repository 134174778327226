import React from "react";
import { type ButtonProps } from "@fluentui/react-components";
import {
  ArrowLeft24Filled,
  ArrowLeft24Regular,
  ArrowRight24Filled,
  ArrowRight24Regular,
  Dismiss24Filled,
  Dismiss24Regular,
} from "@fluentui/react-icons";
import StylesConfigFluent from "../../../config/styles-config-fluent";
import { IconButtonFluent } from "./icon-button-fluent";

export const closeBannerButtonId = "close-button";
export const backBannerButtonId = "back-button";

export type BannerButtonProps = ButtonProps & {
  isRtlMarket?: boolean;
  showBackButtonOnActiveView?: boolean;
  showCloseButtonOnActiveView?: boolean;
};

/**
 * Banner Button component
 * @param props BannerButtonProps
 * @returns an instance of the button component used in the banner above the view container, displaying either an
 * arrow or dismiss icon.
 */
export const BannerButtonFluent: React.FC<BannerButtonProps> = function BannerButtonFluent(props) {
  const { isRtlMarket, showBackButtonOnActiveView, showCloseButtonOnActiveView } = props;
  const { hiddenStyles } = StylesConfigFluent.instance.useCommonStyles();

  // Hide button by default so it takes up space in the DOM but is not visible unless needed
  let label = getLocalString("General_Close");
  let dataTestId = "dismissIcon";
  let buttonId = closeBannerButtonId;
  let className = hiddenStyles;
  let filledIcon = Dismiss24Filled;
  let regularIcon = Dismiss24Regular;

  if (showBackButtonOnActiveView) {
    label = getLocalString("General_Back");
    buttonId = backBannerButtonId;
    className = "";

    if (isRtlMarket) {
      dataTestId = "rightArrowIcon";
      filledIcon = ArrowRight24Filled;
      regularIcon = ArrowRight24Regular;
    } else {
      dataTestId = "leftArrowIcon";
      filledIcon = ArrowLeft24Filled;
      regularIcon = ArrowLeft24Regular;
    }
  }

  if (showCloseButtonOnActiveView) {
    className = "";
  }

  return (
    <IconButtonFluent
      {...props}
      id={buttonId}
      label={label}
      filledIcon={filledIcon}
      regularIcon={regularIcon}
      buttonClassName={className}
      data-testid={dataTestId}
    />
  );
};
