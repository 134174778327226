import { useFormSubmission } from "@msidentity/SISU/components/inputs/hooks/use-form-submission";
import { usePhoneNumberFluent } from "@msidentity/SISU/components/inputs/phone-number/fluent/hooks/use-phone-number-fluent";
import { FlowId, ViewId } from "@msidentity/SISU/constants";
import LoginConfig from "@msidentity/SISU/flows/login/login-config";
import {
  useGctRequest,
  useSignInDifferentUserHandler,
} from "@msidentity/SISU/flows/login/views/phone-disambiguation/hooks/phone-disambiguation-view-hooks";
import { useGlobalContext } from "@msidentity/SISU/global-context";
import { useActivateViewWithDocumentTitle } from "@msidentity/SISU/hooks";
import { getPhoneInputValidation } from "@msidentity/SISU/model/alias";
import { getDefaultCountry } from "@msidentity/SISU/utilities/country-helper";
import { type PhoneDisambiguationViewRendererFluentProps } from "@msidentity/SISU/views/phone-disambiguation";
import { type PhoneDisambiguationViewStringsFluent } from "./phone-disambiguation-view-strings-fluent";

/**
 * Aggregator hook for the login Phone Disambiguation view.
 * @param strings The strings for the Phone Disambiguation view
 * @returns {PhoneDisambiguationViewRendererFluentProps} The props required by the Phone Disambiguation view renderer
 */
export const usePhoneDisambiguationViewFluent = function usePhoneDisambiguationViewFluent(
  strings: PhoneDisambiguationViewStringsFluent,
): PhoneDisambiguationViewRendererFluentProps {
  const { countryList, defaultCountry: defaultPhoneCountry } = LoginConfig.instance;
  const {
    globalState: { user },
  } = useGlobalContext();

  useActivateViewWithDocumentTitle(strings.title, ViewId.PhoneDisambiguation, FlowId.Login, {
    showIdentityBanner: false,
    showBackButtonOnActiveView: true,
  });

  const { inputProps, dropdownProps, fieldProps, phoneNumberState } = usePhoneNumberFluent({
    input: { initialValue: user.username.unsafeUnescapedString },
    defaultCountry: getDefaultCountry(countryList, defaultPhoneCountry),
    countryData: countryList,
    validationMethod: getPhoneInputValidation(strings.invalidPhoneFormatError),
  });

  const submitTask = useGctRequest();

  const onSubmitHandler = useFormSubmission({
    inputState: { ...phoneNumberState, elementRef: phoneNumberState.inputState.elementRef },
    viewId: ViewId.PhoneDisambiguation,
    submitTask,
    submitParams: {
      value: phoneNumberState.value,
      inputValue: String(phoneNumberState.inputState.value),
      dropdownValue: phoneNumberState.dropdownValue,
      errorHandler: phoneNumberState.error.setServerError,
    },
  });

  const signInWithDifferentUserOnClick = useSignInDifferentUserHandler();

  return {
    strings,
    handlers: {
      onSubmitHandler,
      signInWithDifferentUserLinkHandler: () =>
        signInWithDifferentUserOnClick(phoneNumberState.value),
    },
    phoneNumberProps: {
      inputProps: {
        ...inputProps,
        placeholder: strings.placeholder,
      },
      dropdownProps,
      fieldProps,
    },
  };
};
