import React from "react";
import { type TextPresetProps, Body1 } from "@fluentui/react-components";
import StylesConfigFluent from "../../../config/styles-config-fluent";
import {
  type FormattedTextWithBindingsFluentProps,
  FormattedTextWithBindingsFluent,
} from "../../../utilities/formatted-text-with-bindings";

export type TextAlignment = TextPresetProps["align"];

export type DescriptionFluentProps = FormattedTextWithBindingsFluentProps & {
  id?: string;
  dataTestId?: string;
  textAlignment?: TextAlignment;
};

/**
 * Description component
 * @param props FormattedTextWithBindingsFluent
 * @param props.id The id of the component.
 * @param props.dataTestId The data-testid of the component.
 * @param props.textAlignment The alignment of the description text. Default is left or right aligned depending on the market.
 * @returns A FormattedTextWithBindingsFluent with Body1 styles.
 */
export const DescriptionFluent: React.FC<DescriptionFluentProps> = function DescriptionFluent(
  props: DescriptionFluentProps,
) {
  const { text, embeddedBindings, wrapperStyleName, id, dataTestId, textAlignment } = props;
  const { defaultTextColorStyles } = StylesConfigFluent.instance.useCommonStyles();

  return (
    <div className={wrapperStyleName} data-testid={dataTestId || "description"} id={id}>
      <Body1 className={defaultTextColorStyles} align={textAlignment}>
        <FormattedTextWithBindingsFluent embeddedBindings={embeddedBindings} text={text} />
      </Body1>
    </div>
  );
};
