import React from "react";
import { type ButtonProps, Button, Tooltip } from "@fluentui/react-components";
import { type FluentIcon, bundleIcon } from "@fluentui/react-icons";
import { iconButtonFillColor } from "./button-fluent.styles";

export type IconButtonProps = ButtonProps & {
  regularIcon: FluentIcon;
  filledIcon: FluentIcon;
  label: string;
  buttonClassName?: string;
};

/**
 * Icon Button component
 * @param props IconButtonProps
 * @returns an instance of the button component displaying an icon.
 */
export const IconButtonFluent: React.FC<IconButtonProps> = function IconButtonFluent(props) {
  const {
    regularIcon,
    filledIcon,
    label,
    buttonClassName,
    size = "small",
    "aria-label": ariaLabel,
  } = props;
  // regularIcon is shown by default, and filledIcon is shown on hover
  const Icon = bundleIcon(regularIcon, filledIcon);

  return (
    <Tooltip content={label} relationship="label">
      <Button
        {...props}
        appearance="transparent"
        aria-label={ariaLabel || label}
        icon={<Icon primaryFill={iconButtonFillColor} />}
        size={size}
        className={buttonClassName}
      />
    </Tooltip>
  );
};
