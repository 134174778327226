import React from "react";
import {
  type FieldProps,
  type InputProps,
  Field,
  Input,
  Label,
  mergeClasses,
  useId,
} from "@fluentui/react-components";
import StylesConfigFluent from "../../../../config/styles-config-fluent";

export type InnerInputFluentProps = InputProps & {
  wrapperStyleName?: string;
  inputStyleName?: string;
  fieldStyleName?: string;
  ref?: React.RefObject<HTMLInputElement>;
};

// Force label to be provided in inputProps
export type InnerFieldProps = Omit<FieldProps, "label" | "contentBefore">;

export type InputFluentProps = {
  inputProps: InnerInputFluentProps;
  fieldProps?: InnerFieldProps;
};

/**
 * Fluent Input with a placeholder that floats up to be used as the label when text is entered.
 * It also supports a validation message and state with the Field component.
 * @param props InputFluentProps
 * @param props.inputProps The properties for the Input component. The placeholder is used as a label.
 * @param props.fieldProps The properties for the Field component. Cannot accept a label, as we use the input placeholder as the label.
 * @returns InputFluent component with a floating label and an optional validation field
 */
export const InputFluent: React.FC<InputFluentProps> = function InputFluent(props) {
  const { inputProps, fieldProps } = props;
  const { wrapperStyleName, inputStyleName, fieldStyleName, value, placeholder, id, disabled } =
    inputProps;
  const {
    floatingLabelContainerStyles,
    floatingPlaceholderStyles,
    floatingLabelStyles,
    disabledFloatingPlaceholderStyles,
    inputStyles,
  } = StylesConfigFluent.instance.useInputStyles();

  const labelStyleName = mergeClasses(
    floatingPlaceholderStyles,
    disabled && disabledFloatingPlaceholderStyles,
    !!value && floatingLabelStyles,
  );

  const mergedWrapperStyleName = mergeClasses(floatingLabelContainerStyles, wrapperStyleName);
  const mergedInputStyleName = mergeClasses(inputStyles, inputStyleName);

  const defaultId = useId("floatingLabelInput");
  const inputId = id ?? defaultId;

  const placeholderLabel = (
    <Label htmlFor={inputId} className={labelStyleName}>
      {placeholder}
    </Label>
  );

  const inputFluentProps = {
    ...inputProps,
    placeholder: "", // use our label as placeholder instead
    id: inputId,
    contentBefore: placeholderLabel,
  };

  return (
    <div data-testid={id} className={mergedWrapperStyleName}>
      <Field {...fieldProps} className={fieldStyleName}>
        <Input {...inputFluentProps} size="large" className={mergedInputStyleName} />
      </Field>
    </div>
  );
};
