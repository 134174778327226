import React from "react";
import { Routes } from "react-router-dom";
import { useRouteAnimationFluent } from "./hooks/use-route-animation-fluent";

/**
 * A shared component for applying view transition animation properties with the `useRouteAnimationFluent` hook.
 * @param props The component properties
 * @param props.children The child elements to render inside this component. This element must be declared inside a route component.
 * @returns route animation wrapper
 */
const RouteAnimationFluent: React.FC = function RouteAnimationFluent({ children }) {
  const { currentLocation, animationStyles, onAnimationEnd } = useRouteAnimationFluent();

  return (
    <div
      data-testid="routeAnimationFluent"
      className={animationStyles}
      onAnimationEnd={onAnimationEnd}
    >
      <Routes location={currentLocation}>{children}</Routes>
    </div>
  );
};

export default RouteAnimationFluent;
