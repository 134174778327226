import React from "react";
import { mergeClasses } from "@fluentui/react-components";
import StylesConfigFluent from "../../../config/styles-config-fluent";
import {
  type EmbeddedLinkPropsFluent,
  createEmbeddedLinkFluent,
  FormattedTextWithBindingsFluent,
} from "../../../utilities/formatted-text-with-bindings";

export type LinkOrEmbeddedLinkFluentProps = {
  text: string;
  linkProps?: EmbeddedLinkPropsFluent;
  bindings?: Record<string, (chunks: string[]) => JSX.Element>;
  leftAligned?: boolean;
  addSectionPadding?: boolean;
};

/**
 * Creates a link or embedded link to pass as a child component to a view as a secondary link. Can be either
 *    left-aligned or center-aligned and cam have no section padding or section padding added above it.
 * @param props LinkOrEmbeddedLinkFluentProps
 * @param props.text The text to display.
 * @param props.linkProps optional EmbeddedLinkPropsFluent used to create a link for use as a view child component.
 * @param props.bindings optional If bindings are passed, FormattedTextWithBindingsFluent will be used create
 *   a link embedded in the text.
 * @param props.leftAligned optional Defaults to false. If true, the text will be left aligned.
 * @param props.addSectionPadding optional Defaults to true. If true, the text will have section padding at the top.
 * @returns A link or button styled as a link, either embedded in the text or as a separate link. Fallback is a string.
 */
export const LinkOrEmbeddedLinkFluent: React.FC<LinkOrEmbeddedLinkFluentProps> =
  function LinkOrEmbeddedLinkFluent(props: LinkOrEmbeddedLinkFluentProps) {
    const { bindings, text, linkProps, leftAligned = false, addSectionPadding = true } = props;
    const { sectionPaddingTopStyles, textAlignCenterStyles } =
      StylesConfigFluent.instance.useCommonStyles();

    let styles = addSectionPadding ? sectionPaddingTopStyles : "";
    styles = leftAligned ? styles : mergeClasses(styles, textAlignCenterStyles);

    if (bindings) {
      return (
        <FormattedTextWithBindingsFluent
          text={text}
          embeddedBindings={bindings}
          wrapperStyleName={styles}
        />
      );
    }

    if (linkProps) {
      const link = createEmbeddedLinkFluent(linkProps);
      return <div className={styles}>{link([text])}</div>;
    }

    return <div className={styles}>{text}</div>;
  };
