import React from "react";
import { Button, mergeClasses } from "@fluentui/react-components";
import { Eye20Regular, EyeOff20Regular, Info12Regular } from "@fluentui/react-icons";
import StylesConfigFluent from "../../../../config/styles-config-fluent";
import { type PasswordStateFluent } from "../../hooks/fluent/use-password-input-fluent";
import { usePasswordRevealFluent } from "../../hooks/fluent/use-password-reveal-fluent";
import {
  type InnerFieldProps,
  type InnerInputFluentProps,
  InputFluent,
} from "../../input/fluent/input-fluent";

// This type omits the hard-coded values we use in the PasswordInput component for Input, so we don't try to use them
// and joins them with the properties returned from the usePasswordInputFluent hook
type PasswordInputProps = Omit<InnerInputFluentProps, "type" | "contentAfter" | "inputStyleName"> &
  PasswordStateFluent["inputProps"];

// This type joins the Field properties with the validationState property required
type PasswordFieldProps = InnerFieldProps &
  Partial<Pick<PasswordStateFluent["fieldProps"], "validationState">>;

// This type picks the properties we need from the inputState property
type PasswordInputState = Pick<PasswordStateFluent["inputState"], "value">;

export type PasswordFluentProps = {
  fieldProps?: PasswordFieldProps;
  inputProps: PasswordInputProps;
  inputState: PasswordInputState;
  addMarginTop?: boolean;
  strings: {
    placeholder: string;
    showPassword?: string;
    hidePassword?: string;
  };
};

/**
 * This component is a password input field that allows the user to reveal the password.
 * It also has an optional "hint" for password collection that can be used to provide additional information to the user.
 * @param props The properties required for this component to function.
 * @returns the PasswordFluent component
 */
export const PasswordFluent: React.FC<PasswordFluentProps> = function PasswordFluent(props) {
  const {
    fieldProps = {},
    inputProps,
    inputState,
    addMarginTop = false,
    strings: {
      hidePassword = getLocalString("Password_HidePasswordLabel"),
      showPassword = getLocalString("Password_ShowPasswordLabel"),
      placeholder,
    },
  } = props;
  const { passwordInputStyles } = StylesConfigFluent.instance.useInputStyles();
  const { sectionPaddingTopStyles } = StylesConfigFluent.instance.useCommonStyles();

  const { buttonRef, onBlurInput, inputType, onClickButton, passwordRevealed } =
    usePasswordRevealFluent({ inputProps, inputState });

  const buttonIcon = passwordRevealed ? (
    <EyeOff20Regular data-testid="password-eye-off" />
  ) : (
    <Eye20Regular data-testid="password-eye-on" />
  );
  const validationMessageIcon =
    fieldProps.validationState === "none" && fieldProps.validationMessage ? (
      <Info12Regular data-testid="password-info-icon" />
    ) : undefined;

  const button = (
    <Button
      aria-label={passwordRevealed ? hidePassword : showPassword}
      appearance="transparent"
      ref={buttonRef}
      icon={buttonIcon}
      size="small"
      onClick={onClickButton}
    />
  );

  return (
    <InputFluent
      inputProps={{
        ...inputProps,
        type: inputType,
        contentAfter: button,
        placeholder,
        onBlur: onBlurInput,
        inputStyleName: passwordInputStyles,
        wrapperStyleName: addMarginTop
          ? mergeClasses(inputProps.wrapperStyleName, sectionPaddingTopStyles)
          : inputProps.wrapperStyleName,
      }}
      fieldProps={{ ...fieldProps, validationMessageIcon }}
    />
  );
};
