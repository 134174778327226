import React from "react";
import { type ButtonProps, Button, Spinner } from "@fluentui/react-components";
import StylesConfigFluent from "../../../config/styles-config-fluent";
import { useGlobalContext } from "../../../global-context";

export type ViewButtonProps = OmitWithoutExpanding<
  ButtonProps,
  "appearance" | "size" | "onClick"
> & {
  buttonText: string;
  onClick?: (event: React.SyntheticEvent<HTMLElement>) => void;
  appearance?: "primary" | "secondary" | "subtle";
};

/**
 * View button component
 * PLEASE NOTE: This component is not expected to be used directly. Instead, use the ViewContainerFluent component.
 * The view button component is exported here to allow for exceptional views that may need a customized layout not
 * achievable using the standard view container layout.
 * @param props ViewButtonProps
 * @returns a button component styled as a view button.
 */
export const ViewButtonFluent: React.FC<ViewButtonProps> = function ViewButtonFluent(props) {
  const {
    globalState: { showProgressIndicator },
  } = useGlobalContext();

  const { appearance, buttonText, onClick, disabled } = props;
  const { viewButtonStyles } = StylesConfigFluent.instance.useButtonStyles();

  // Loading spinner is only shown on primary buttons and also only when the disabled prop is not explicitly sent
  const isLoading = appearance === "primary" && showProgressIndicator && disabled === undefined;

  return (
    <Button
      {...props}
      className={viewButtonStyles}
      size="large"
      onClick={onClick}
      disabled={disabled === undefined ? showProgressIndicator : disabled}
    >
      {isLoading ? <Spinner size="tiny" /> : buttonText}
    </Button>
  );
};
