import React from "react";
import { mergeClasses } from "@fluentui/react-components";
import StylesConfigFluent from "../../../config/styles-config-fluent";
import {
  type FormattedTextWithBindingsFluentProps,
  FormattedTextWithBindingsFluent,
} from "../../../utilities/formatted-text-with-bindings";

/**
 * Caption component for Fluent
 * @param props FormattedTextWithBindingsWrapperFluentProps
 * @returns a FormattedTextWithBindingWrapperFluent component styled as a caption.
 */
export const CaptionFluent: React.FC<FormattedTextWithBindingsFluentProps> = function CaptionFluent(
  props: FormattedTextWithBindingsFluentProps,
) {
  const { text, embeddedBindings, wrapperStyleName } = props;
  const { captionStyles } = StylesConfigFluent.instance.useViewContainerStyles();
  const captionFluentStyles = mergeClasses(captionStyles, wrapperStyleName);

  return (
    <div data-testid="caption">
      <FormattedTextWithBindingsFluent
        wrapperStyleName={captionFluentStyles}
        embeddedBindings={embeddedBindings}
        text={text}
      />
    </div>
  );
};
