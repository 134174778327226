import { useAuthContext } from "@msidentity/SISU/authentication-context";
import { loginOption as LoginOption } from "@msidentity/sisu/constants";
import LoginConfig from "@msidentity/SISU/flows/login/login-config";
import { useLoginContext } from "@msidentity/SISU/flows/login/login-context";
import { type LoginPostProps } from "@msidentity/SISU/flows/login/login-types";
import GlobalConfig from "@msidentity/SISU/global-config";
import { useGlobalContext } from "@msidentity/SISU/global-context";
import { cleanseUsername } from "@msidentity/SISU/model/user";
import { getFidoSupport } from "@msidentity/SISU/utilities/browser-helper";

/**
 * Common hook for MSA login views which render LoginFlowPostHiddenInputs to submit
 * hidden input values with the form on Post
 * @param paginatedState The current view (use enum LoginState)
 * @param postType The type of data to be posted to the server (use enum postType)
 * @returns Common properties for login form post submission
 */
export const useLoginPostProps = (paginatedState: number, postType: number): LoginPostProps => {
  const {
    context,
    canaryTokenValue: canary,
    flowTokenName,
    showCookieBanner,
  } = GlobalConfig.instance;

  const {
    defaultLoginOptions,
    foundMsas,
    isFidoSupportedHint,
    postedForceSignIn,
    randomBlob,
    useWebviewFidoCustomProtocol,
  } = LoginConfig.instance;

  const {
    globalState: { user },
  } = useGlobalContext();

  const {
    viewState: { remoteNgcParams },
  } = useLoginContext();

  const {
    authState: { flowTokenValue },
  } = useAuthContext();

  const cleansedUsername = cleanseUsername(user.username.unsafeUnescapedString);
  const displayUsername = user.displayUsername.safeHtmlEscapedString;
  // TODO: this may not be needed in Fluent/going forward. Should it be optional/removed?
  const isFidoSupported = getFidoSupport(isFidoSupportedHint, useWebviewFidoCustomProtocol);
  const isKmsiChecked = defaultLoginOptions === LoginOption.rememberPwd;
  const loginOption = isKmsiChecked ? LoginOption.rememberPwd : LoginOption.nothingChecked;

  return {
    canary,
    cleansedUsername,
    context,
    displayUsername,
    flowTokenName,
    flowTokenValue,
    foundMsas,
    isFidoSupported,
    isKmsiChecked,
    loginOption,
    paginatedState,
    postType,
    postedForceSignIn,
    randomBlob,
    rngcDefaultType: remoteNgcParams.defaultType,
    rngcEntropy: remoteNgcParams.entropy,
    rngcSessionIdentifier: remoteNgcParams.sessionIdentifier,
    showCookieBanner,
  };
};
