import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const useDomainSelectStylesFluent = makeStyles({
  domainSelectWrapperStyles: {
    "& select": { lineHeight: tokens.lineHeightBase400, fontSize: tokens.fontSizeBase300 },
    display: "flex",
    alignItems: "end",
    whiteSpace: "nowrap",
    width: "100%",
  },

  domainSelectSpanStyles: {
    ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS),
  },
});

export type DomainSelectStylesDefaultFluent = ReturnType<typeof useDomainSelectStylesFluent>;
