import {
  type FieldValidationState,
  type InputStateFluent,
  type InputStateOptionsFluent,
} from "../../input/fluent/input-types-fluent";
import { type InputErrorState, useInput } from "../use-input";

/**
 * This method takes the InputErrorState and determines the validationState and validationMessage based on the error state.
 * @param inputErrorState the InputErrorState from `useErrorState`
 * @returns an object containing the validationState and validationMessage, used for Fluent components
 */
export const determineValidation = (inputErrorState: InputErrorState) => {
  const { showErrorMessage, hasError, errorMessage } = inputErrorState;
  // Default state to none with no message
  // This will have the Field render nothing
  let validationState: FieldValidationState = "none";
  let validationMessage: string | JSX.Element = "";

  // If we have an error to show, show the error and set the state to error
  if (showErrorMessage && hasError) {
    validationState = "error";
    validationMessage = errorMessage;
  }

  return {
    validationState,
    validationMessage,
  };
};

/**
 * This hooks uses the base `useInput` hook to create/manage state for mostly any Fluent input.
 * It also determines the validation state based on the input state to provide a Fluent component.
 * @param options The input state options, with `useElementRef` removed as it is always true.
 * @returns the InputState for any form submission; the inputProps and fieldProps for use with InputFluent
 */
export const useInputFluent = (options: InputStateOptionsFluent): InputStateFluent => {
  // Hard-code the useInput to always use elementRef
  const inputState = useInput({
    ...options,
    useElementRef: true,
  });
  // Determine the validation state using the input state
  const fieldProps = determineValidation(inputState.error);

  const inputProps = {
    value: String(inputState.value),
    onChange: inputState.onChange,
    ref: inputState.elementRef!, // We know that elementRef is defined because we are using useElementRef
  };

  return {
    inputState,
    inputProps,
    fieldProps,
  };
};
