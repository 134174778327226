import React from "react";
import { Text } from "@fluentui/react-components";
import {
  type LinkOrEmbeddedLinkFluentProps,
  LinkOrEmbeddedLinkFluent,
} from "../../link/fluent/link-or-embedded-link-fluent";

export type ViewFooterFluentProps = Omit<
  LinkOrEmbeddedLinkFluentProps,
  "leftAligned" | "addSectionPadding"
>;

/**
 * @param props ViewFooterProps
 * @returns an a link or button styles as a link styled as the view footer.
 */
export const ViewFooterFluent: React.FC<ViewFooterFluentProps> = function ViewFooterFluent(
  props: ViewFooterFluentProps,
) {
  const { bindings, text, linkProps } = props;

  return (
    <Text {...props} data-testid="viewFooter">
      <LinkOrEmbeddedLinkFluent
        text={text}
        linkProps={linkProps}
        bindings={bindings}
        leftAligned={false}
        addSectionPadding
      />
    </Text>
  );
};
