import React from "react";
import { useDetectFluentTheme } from "../../../context/theme/fluent/use-detect-fluent-theme";
import GlobalConfig from "../../../global-config";
import { initialPostRedirectState, PostRedirectProvider } from "../../../post-redirect-context";
import { useTelemetryState } from "../../../telemetry-helpers/use-telemetry-state";
import AppBoundary from "../../app-boundary/app-boundary";
import CriticalErrorPageFluent from "../../cricital-error-page/fluent/critical-error-page-fluent";
import LightboxLayoutFluent from "../../layout/fluent/lightbox-layout-fluent";
import { PostRedirectForm } from "../../post-redirect-form";
import { useFavIcon } from "../use-fav-icon";
import { useShell } from "../use-shell";

/**
 * Shell component for Fluent flavor
 * @param props The properties for this component
 * @param props.children The child elements to render inside this layout
 * @returns The rendered component
 */
const ShellFluent: React.FC = function ShellFluent({ children }) {
  const { isPageBlank } = useShell();
  const telemetryState = useTelemetryState();
  const LayoutComponent = LightboxLayoutFluent;

  const { favIconUrl } = GlobalConfig.instance;
  useFavIcon(favIconUrl);

  useDetectFluentTheme();

  return isPageBlank ? (
    <CriticalErrorPageFluent />
  ) : (
    <AppBoundary telemetryState={telemetryState} CriticalErrorPage={<CriticalErrorPageFluent />}>
      <LayoutComponent>
        <PostRedirectProvider initialState={initialPostRedirectState}>
          <PostRedirectForm />
          {children}
        </PostRedirectProvider>
      </LayoutComponent>
    </AppBoundary>
  );
};

export default ShellFluent;
